import { colors, fontSizes, MediaItem, SortOrder, SurveyMedia } from '@cpm/scanifly-shared-data';

import { UploadResult } from '@uppy/core';
import { Spin } from 'antd';
import { ReactComponent as DownloadCloud } from 'assets/icons/download-cloud.svg';
import { Button, ZipButton } from 'components';
import UploadDashboardModal from 'components/UploadDashboardModal';
import { DownloadButtonWrapper } from 'components/ZipButton/ZipButton';
import {
  ALL_PHOTOS_NAME,
  ALL_PHOTOS_URL_ENCODED,
  DRONE_IMAGES,
  DRONE_IMAGES_CATEGORY,
} from 'helpers/constants/categories';
import useToggle from 'helpers/hooks/useToggle';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { StyledDownloadLink } from 'screens/Albums/ProjectCategory/ProjectCategory';
import { projectMediasRequested } from 'state/slices/mediasSlice';
import { AppDispatch } from 'state/store';
import styled from 'styled-components';
import { Project } from 'types';
import { SortOptionTypes } from '../types';
import { Sorter } from './Sorter';

const SorterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid ${colors.neutralGray};
`;

const SelectedImageCount = styled.div`
  color: ${colors.labelGray};
  font-size: ${fontSizes.small};
  vertical-align: center;
  margin-right: 1rem;
`;

const DownloadWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const SorterDisplay = ({
  selectedImages,
  album,
  handleSort,
  project,
  categoryName,
  isZipLoading,
  setIsZipLoading,
}: {
  selectedImages: Record<string, MediaItem | SurveyMedia>;
  album: (MediaItem | SurveyMedia)[];
  handleSort: ({ sortByField, order }: { sortByField: SortOptionTypes; order: SortOrder }) => void;
  project: Project | null;
  categoryName: string;
  isZipLoading?: boolean;
  setIsZipLoading?: (loading: boolean) => void;
}) => {
  const [isUploadModalVisible, toggleIsUploadModalVisible] = useToggle(false);
  const selectedImageLength = Object.keys(selectedImages).length;
  const albumLength = Object.keys(album).length;
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();

  const onUploadComplete = (_: UploadResult) => {
    toggleIsUploadModalVisible();
    if (project?.id) {
      dispatch(
        projectMediasRequested({
          projectId: project.id,
          mediaCategoryName: categoryName,
          withFileExtension: false,
        })
      );
    }
  };

  const isAllPhotos = categoryName === ALL_PHOTOS_NAME || categoryName === ALL_PHOTOS_URL_ENCODED;
  const isDroneImages = categoryName === DRONE_IMAGES || categoryName === DRONE_IMAGES_CATEGORY;

  return (
    <SorterContainer>
      <Sorter
        handleSort={handleSort}
        label={t('AlbumView.name')}
        sortByField="name"
        tooltipText={t('AlbumView.sortTooltipText')}
      />
      <DownloadWrapper>
        {categoryName ? (
          <UploadDashboardModal
            isModalVisible={isUploadModalVisible}
            toggleIsModalVisible={toggleIsUploadModalVisible}
            handleUploadComplete={onUploadComplete}
            categoryName={categoryName}
            endpoint={`${process.env.REACT_APP_API}/surveyMedias/upload-xhr`}
          />
        ) : null}
        {selectedImageLength && !isZipLoading ? (
          <SelectedImageCount>
            {t('AlbumView.selectedText', { selectedImageLength, albumLength })}
          </SelectedImageCount>
        ) : null}
        {selectedImageLength && isZipLoading ? (
          <SelectedImageCount>
            {t('buttonTexts.downloadingSelected', { selectedImageLength, albumLength })}
          </SelectedImageCount>
        ) : null}
        {!selectedImageLength && isZipLoading ? (
          <SelectedImageCount>
            {t('buttonTexts.downloadingAll', { albumLength })}
          </SelectedImageCount>
        ) : null}
        {selectedImageLength === 1 ? (
          <StyledDownloadLink
            id="image-download"
            href={Object.values(selectedImages)[0]?.imgUrl}
            download
            rel="noreferrer"
            data-testid="single-image-download-link"
          >
            <DownloadButtonWrapper
              style={{
                pointerEvents: isZipLoading ? 'none' : 'auto',
                cursor: isZipLoading ? 'not-allowed' : 'pointer',
                opacity: isZipLoading ? 0.6 : 1,
              }}
            >
              {isZipLoading ? (
                <Spin
                  size="small"
                  style={{ marginTop: '0.5rem' }}
                  indicator={
                    <div className="ant-spin-dot">
                      <span className="ant-spin-dot-item" style={{ backgroundColor: '#ffff' }} />
                      <span className="ant-spin-dot-item" style={{ backgroundColor: '#ffff' }} />
                      <span className="ant-spin-dot-item" style={{ backgroundColor: '#ffff' }} />
                      <span className="ant-spin-dot-item" style={{ backgroundColor: '#ffff' }} />
                    </div>
                  }
                />
              ) : (
                <DownloadCloud />
              )}
              <p>{t('buttonTexts.download')}</p>
            </DownloadButtonWrapper>
          </StyledDownloadLink>
        ) : (
          <ZipButton
            files={selectedImageLength > 0 ? Object.values(selectedImages) : album}
            folderName={`${project?.name} ${categoryName}`}
            buttonText={t('buttonTexts.download')}
            isLoading={isZipLoading}
            setIsLoading={setIsZipLoading}
          />
        )}
        <Button
          icon="cloudUpload"
          label={t('buttonTexts.upload')}
          color={colors.mainBlue}
          iconSize="1.85rem"
          width="11rem"
          data-testid="image-upload-button"
          onClick={toggleIsUploadModalVisible}
          disabled={Boolean(isDroneImages || isAllPhotos)}
        />
      </DownloadWrapper>
    </SorterContainer>
  );
};
