import { colors } from '@cpm/scanifly-shared-data';
import { ReactComponent as DroneCrash } from 'assets/dronecrash-animation.svg';
import fontSizes from 'helpers/constants/fontSizes';
import fontWeights from 'helpers/constants/fontWeights';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 5rem;
  height: calc(100vh - 28rem);
  width: 100%;
`;

const StyledNotice = styled.div`
  font-size: ${fontSizes.small};
  color: ${colors.darkGray};
  width: 50%;
  text-align: center;
  white-space: pre-line;
`;

const StyledNoticeTitle = styled(StyledNotice)`
  font-size: ${fontSizes.medium};
  font-weight: ${fontWeights.semiBold};
  margin-bottom: 1rem;
`;

const StyledDroneCrashIcon = styled(DroneCrash)`
  height: 20rem;
  width: auto;
`;

export const DroneUploadsNotAvailable = () => {
  const { t } = useTranslation();
  return (
    <StyledWrapper>
      <StyledNoticeTitle>{t('AlbumView.whoops')}</StyledNoticeTitle>
      <StyledNotice>{t('AlbumView.droneUploadDisclaimer')}</StyledNotice>
      <StyledDroneCrashIcon />
    </StyledWrapper>
  );
};
