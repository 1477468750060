import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { DESIGN_STATUS, TIER_LEVEL } from '@cpm/scanifly-shared-data';
import { Modal, Spin } from 'antd';
import { useFormik } from 'formik';
import {
  CANCELLATION_OPTIONS,
  FORM_CONTROLS,
  FORM_CONTROLS_MODEL,
  initialValues,
  MODALS,
  MODEL_PROBLEMS,
} from 'screens/DesignServices/constants';
import { Revision, ServiceRequest } from 'types';

import {
  resetUpdatedServiceRequest,
  serviceRequestsRequested,
  serviceRequestUpdateRequested,
} from 'state/slices/designServices/designServiceRequestsSlice';
import { projectRequested } from 'state/slices/projectSlice';
import { AppDispatch, RootState } from 'state/store';

import { ModalContext } from 'components';
import ContactScaniflySupport from 'components/ContactScaniflySupport/ContactScaniflySupport';

import { openNotification } from 'helpers/utils/openNotification';
import {
  getAllDesignStatusSteps,
  getContentToRender,
} from 'screens/DesignServices/helpers/getAllDesignStatusSteps';
import { getDesignStatusStep } from 'screens/DesignServices/helpers/getDesignStatusStep';

import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import DesignSteps from '../DesignSteps/DesignSteps';
import CancelOrderModal from './CancelOrderModal/CancelOrderModal';
import { cancelationValidationSchema } from './CancelOrderModal/validationSchema';
import './DesignStatus.scss';
import RevisionDataModal from './RevisionDataModal/RevisionDataModal';
import RevisionModal from './RevisionModal/RevisionModal';
import { revisionValidationSchema } from './RevisionModal/validationSchema';
import DesignCanceled from './StatusContent/DesignCanceled';

export enum StepStatus {
  error = 'error',
  finish = 'finish',
  wait = 'wait',
  process = 'process',
}

const DesignStatus = () => {
  const { displayFormModal, setActionButtonDisabled } = useContext(ModalContext);
  const { projectId, step } = useParams<{ projectId: string; step: string }>();
  const dispatch: AppDispatch = useDispatch();
  const [selectedStep, setSelectedStep] = useState<number>(Number(step));
  const [cancellationModalVisible, setCancellationModalVisible] = useState<boolean>(false);
  const [revisionDataModalVisible, setRevisionDataModalVisible] = useState<boolean>(false);
  const [revisionData, setRevisionData] = useState<Revision | null>(null);
  const [isCancelOrderFormSubmitted, setIsCancelOrderFormSubmitted] = useState(false);
  const [content, setContent] = useState<React.JSX.Element | undefined>();
  const [stepStatus, setStepStatus] = useState<StepStatus | undefined>();
  const { serviceRequests, incompleteServiceRequest, isLoading, updatedServiceRequest } =
    useSelector((state: RootState) => state.designServiceRequests);
  const { isProjectRequestedLoading } = useSelector((state: RootState) => state.project);

  const { project, status, tierLevel, id: serviceRequestId } = incompleteServiceRequest || {};
  const { status: projectStatus } = project || {};

  const hasSitePlanOrPlanSet = serviceRequests?.filter(
    (r) =>
      r.status !== DESIGN_STATUS.canceled &&
      (r.tierLevel === TIER_LEVEL.planSet || r.tierLevel === TIER_LEVEL.sitePlan)
  ).length;

  const lastRequest = useMemo(
    () =>
      serviceRequests
        ?.filter((r: ServiceRequest) => r.status !== DESIGN_STATUS.canceled)
        .reduce(
          (a: ServiceRequest, b: ServiceRequest) =>
            (a?.createdAt ?? '') > (b?.createdAt ?? '') ? a : b,
          {} as ServiceRequest
        ),
    [serviceRequests]
  );

  const tierLevelForCompleted = hasSitePlanOrPlanSet ? TIER_LEVEL.sitePlan : TIER_LEVEL.wireframe;

  const getDesignStatusSteps = getAllDesignStatusSteps(
    incompleteServiceRequest ? tierLevel : tierLevelForCompleted,
    incompleteServiceRequest?.status
  );

  const serviceRequestToBeRevised = incompleteServiceRequest ?? lastRequest;
  const serviceRequestIdToBeRevised = serviceRequestId ?? lastRequest?.id;

  useEffect(() => {
    if (projectId) {
      dispatch(serviceRequestsRequested({ projectId }));
      dispatch(projectRequested(projectId));
    }
  }, [dispatch, projectId]);

  useEffect(() => {
    if (updatedServiceRequest) {
      resetUpdatedServiceRequest();
      window.location.reload();
    }
  }, [dispatch, projectId, updatedServiceRequest]);

  useEffect(() => {
    setSelectedStep(getDesignStatusStep({ projectStatus, status, tierLevel }));
  }, [projectStatus, status, tierLevel]);

  const { handleChange, handleSubmit, setFieldValue, getFieldProps } = useFormik({
    initialValues,
    onSubmit: (values, { resetForm }) => {
      setFieldValue(FORM_CONTROLS.CANCEL_NOTE, values.cancelNote);
      const cancelationReasons = [];
      for (const key in values) {
        if (values[key] === true) {
          cancelationReasons.push(CANCELLATION_OPTIONS.find((val) => val.name === key)?.text);
        }
      }
      const finalData = {
        cancelation: {
          note: values.cancelNote ?? 'n/a',
          reasons: cancelationReasons,
        },
      };

      if (serviceRequestId) {
        dispatch(
          serviceRequestUpdateRequested({
            serviceRequestId,
            finalData,
            handleSuccess: handleCancellationSuccess,
            handleError,
            status: DESIGN_STATUS.canceled,
          })
        );
      }

      resetForm();
    },
    validationSchema: cancelationValidationSchema(),
  });

  const handleOpenRevisionDataModal = useCallback((revision: Revision) => {
    setRevisionData(revision);
    setRevisionDataModalVisible(true);
  }, []);

  const {
    handleChange: handleChangeRevision,
    handleSubmit: handleSubmitRevision,
    getFieldProps: getFieldPropsRevision,
  } = useFormik({
    initialValues,
    onSubmit: async (values, { resetForm }) => {
      setFieldValue(FORM_CONTROLS_MODEL.NOTE, values.note);
      const modelProblems = [];
      for (const key in values) {
        if (values[key] === true) {
          modelProblems.push(MODEL_PROBLEMS.find((val) => val.name === key)?.text);
        }
      }

      const finalData = {
        modelRevision: {
          note: values.note,
          reasons: modelProblems,
        },
      };

      if (serviceRequestIdToBeRevised) {
        await dispatch(
          serviceRequestUpdateRequested({
            serviceRequestId: serviceRequestIdToBeRevised,
            finalData,
            handleSuccess: handleModalClose,
            handleError,
            status: DESIGN_STATUS.revisionInProgress,
          })
        );
      }

      await resetForm();
    },
    validationSchema: revisionValidationSchema(),
  });

  const handleCancellationSuccess = () => {
    handleModalClose();
    setIsCancelOrderFormSubmitted(true);
  };

  const handleExtraFeeApproval = useCallback(
    (e: CheckboxChangeEvent) => {
      setActionButtonDisabled(!e.target.checked);
    },
    [setActionButtonDisabled]
  );

  const handleError = (description?: string) => {
    handleModalClose();
    openNotification({
      type: 'error',
      title: 'Error!',
      text: description ?? (
        <ContactScaniflySupport erroredAction="cancel your design service request" />
      ),
    });
  };

  const handleModalClose = useCallback(() => {
    setCancellationModalVisible(false);
    setRevisionDataModalVisible(false);
    setRevisionData(null);
  }, []);

  const displayRevisionModal = useCallback(() => {
    if (serviceRequestToBeRevised) {
      displayFormModal({
        formFields: (
          <RevisionModal
            handleChange={handleChangeRevision}
            handleSubmit={handleSubmitRevision}
            getFieldProps={getFieldPropsRevision}
            serviceRequest={serviceRequestToBeRevised}
            handleExtraFeeApproval={handleExtraFeeApproval}
          />
        ),
        actionButtonOnClick: handleSubmitRevision,
        actionButtonLabel: 'Submit Request',
        actionButtonTestId: 'ds-revision-submit',
        onCancel: handleModalClose,
      });
      setActionButtonDisabled(true);
    }
  }, [
    serviceRequestToBeRevised,
    displayFormModal,
    handleChangeRevision,
    handleSubmitRevision,
    getFieldPropsRevision,
    handleExtraFeeApproval,
    handleModalClose,
    setActionButtonDisabled,
  ]);

  const handleModalOpen = useCallback(
    (modal = MODALS.CANCELATION) => {
      if (modal === MODALS.REVISION) {
        return displayRevisionModal();
      }
      return setCancellationModalVisible(true);
    },
    [displayRevisionModal]
  );

  const handleStepChange = (step: string | number) => {
    setSelectedStep(Number(step));
  };

  useEffect(() => {
    if (!isLoading) {
      const { content, status: stepStatus } = getContentToRender({
        hasSitePlanOrPlanSet,
        incompleteServiceRequest,
        handleModalOpen,
        serviceRequestToBeRevised,
        handleOpenRevisionDataModal,
      });
      setContent(content);
      setStepStatus(stepStatus);
    }
  }, [
    handleModalOpen,
    handleOpenRevisionDataModal,
    hasSitePlanOrPlanSet,
    incompleteServiceRequest,
    isLoading,
    serviceRequestToBeRevised,
  ]);

  return isLoading || isProjectRequestedLoading || !content || !stepStatus ? (
    <Spin size="large"></Spin>
  ) : (
    <div className="DesignStatus-MainWrapper" data-testid="design-status">
      {isCancelOrderFormSubmitted && incompleteServiceRequest ? (
        <DesignCanceled canceledDesign={incompleteServiceRequest} />
      ) : (
        <>
          {/* @ts-ignore ant-d garbage */}
          <Modal
            visible={revisionDataModalVisible}
            cancelButtonProps={{ style: { display: 'none' } }}
            onOk={handleModalClose}
            okText="Close"
            className="RevisionDataModal"
          >
            {/* @ts-ignore this lib is incompatible with react18*/}
            <RevisionDataModal revision={revisionData} />
          </Modal>
          {/* @ts-ignore this lib is incompatible with react18*/}
          <Modal
            visible={cancellationModalVisible}
            onCancel={handleModalClose}
            onOk={handleSubmit as (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void}
            okText="Cancel Order"
            cancelText="Back"
          >
            <CancelOrderModal
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              getFieldProps={getFieldProps}
            />
          </Modal>
          <DesignSteps
            current={Number(selectedStep)}
            options={getDesignStatusSteps(content)}
            handleStepChange={handleStepChange}
            stepStatus={stepStatus}
            isDesignStatusPage={true}
          >
            {content}
          </DesignSteps>
        </>
      )}
    </div>
  );
};

export default DesignStatus;
