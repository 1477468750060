import React from 'react';

import {
  ButtonContainer,
  StyledAcceptDesignIcon,
  StyledAddCommentIcon,
  StyledAddIcon,
  StyledCheckboxIcon,
  StyledCloudDownloadIcon,
  StyledCloudUploadIcon,
  StyledCollapseIcon,
  StyledCopyIcon,
  StyledDateIcon,
  StyledDeleteIcon,
  StyledDropdownIcon,
  StyledEditIcon,
  StyledEllipsisIcon,
  StyledExpandIcon,
  StyledMediaIcon,
  StyledNewTabIcon,
  StyledNotApplicableIcon,
  StyledRadioIcon,
  StyledRefreshIcon,
  StyledRenameIcon,
  StyledReviewDesignIcon,
  StyledSaveIcon,
  StyledTextIcon,
  StyledTimeIcon,
  StyledToggleIcon,
  StyledUploadIcon,
  StyledXIcon,
  StyledYesNoIcon,
} from './components';

import colors from 'helpers/constants/colors';

import ButtonProps from './props';
import { IconTypes } from './types';

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      icon,
      disabled = false,
      onClick,
      width,
      height,
      color,
      label,
      children,
      justifyContent,
      padding,
      border,
      iconSize,
      id,
      margin,
    }: ButtonProps,
    ref
  ) => {
    const renderIcon = (
      icon: IconTypes | undefined,
      color: colors | undefined,
      iconSize: string | undefined
    ) => {
      switch (icon) {
        case 'save':
          return <StyledSaveIcon color={color} iconsize={iconSize} />;
        case 'edit':
          return <StyledEditIcon color={color} iconsize={iconSize} />;
        case 'delete':
          return <StyledDeleteIcon color={color} iconsize={iconSize} />;
        case 'copy':
          return <StyledCopyIcon color={color} iconsize={iconSize} />;
        case 'radio':
          return <StyledRadioIcon color={color} iconsize={iconSize} />;
        case 'text':
          return <StyledTextIcon color={color} iconsize={iconSize} />;
        case 'date':
          return <StyledDateIcon color={color} iconsize={iconSize} />;
        case 'yesno':
          return <StyledYesNoIcon color={color} iconsize={iconSize} />;
        case 'notapplicable':
          return <StyledNotApplicableIcon color={color} iconsize={iconSize} />;
        case 'checkbox':
          return <StyledCheckboxIcon color={color} iconsize={iconSize} />;
        case 'dropdown':
          return <StyledDropdownIcon color={color} iconsize={iconSize} />;
        case 'media':
          return <StyledMediaIcon color={color} iconsize={iconSize} />;
        case 'time':
          return <StyledTimeIcon color={color} iconsize={iconSize} />;
        case 'toggle':
          return <StyledToggleIcon color={color} iconsize={iconSize} />;
        case 'add':
          return <StyledAddIcon color={color} iconsize={iconSize} />;
        case 'rename':
          return <StyledRenameIcon color={color} iconsize={iconSize} />;
        case 'upload':
          return <StyledUploadIcon color={color} iconsize={iconSize} />;
        case 'clouddownload':
          return <StyledCloudDownloadIcon color={color} iconsize={iconSize} />;
        case 'cloudUpload':
          return <StyledCloudUploadIcon color={color} iconsize={iconSize} />;
        case 'reviewDesign':
          return <StyledReviewDesignIcon color={color} iconsize={iconSize} />;
        case 'acceptDesign':
          return <StyledAcceptDesignIcon color={color} iconsize={iconSize} />;
        case 'addComment':
          return <StyledAddCommentIcon color={color} iconsize={iconSize} />;
        case 'newTab':
          return <StyledNewTabIcon color={color} iconsize={iconSize} />;
        case 'refresh':
          return <StyledRefreshIcon color={color} iconsize={iconSize} />;
        case 'expand':
          return <StyledExpandIcon color={color} iconsize={iconSize} />;
        case 'collapse':
          return <StyledCollapseIcon color={color} iconsize={iconSize} />;
        case 'close':
          return <StyledXIcon color={color} iconsize={iconSize} />;
        case 'kebabMenu':
          return <StyledEllipsisIcon color={color} iconsize={iconSize} />;
        default:
          return null;
      }
    };

    return (
      <ButtonContainer
        ref={ref}
        type="button"
        disabled={disabled}
        width={width}
        color={color}
        height={height}
        margin={margin}
        onClick={(event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          event && event.preventDefault();
          onClick && onClick(event);
        }}
        justifyContent={justifyContent}
        padding={padding}
        border={border}
        data-testid={id}
      >
        {renderIcon(icon, color, iconSize)}
        {label}
        {children}
      </ButtonContainer>
    );
  }
);

export default Button;
