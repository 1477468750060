import { useParams } from 'react-router-dom';

import useHubSpot from 'helpers/hooks/useHubSpot';
import useLaunchDarkly from 'helpers/hooks/useLaunchDarkly';
import useUserpilot from 'helpers/hooks/useUserpilot';
import { useViewportSize } from 'helpers/hooks/useViewportSize';

import './S3DWrapper.scss';

const iframeId = 'S3DIframe';

const S3DV2Wrapper = () => {
  useHubSpot();
  useUserpilot();
  useLaunchDarkly();

  const { projectId, designId } = useParams();
  const { height, width } = useViewportSize();
  const token = localStorage.getItem('accessToken');

  const searchParams = new URLSearchParams(window.location.search);
  let src = `${process.env.REACT_APP_VIEWER_URL}?ID=${projectId}&did=${designId}&token=${token}`;
  if (searchParams.has('version')) {
    src += '&version=' + searchParams.get('version');
  }
  const mode = searchParams.get('mode');
  if (mode) {
    src += `&mode=${mode}`;
  }

  return (
    <div className="S3DWrapper">
      <iframe
        allow="clipboard-read; clipboard-write;"
        id={iframeId}
        title="Scanifly3D"
        onLoad={() => {
          // Set focus on iframe after load for keyboard events
          const iframe = document.getElementById(iframeId);
          if (iframe) iframe.contentWindow.focus();
        }}
        role="application"
        className="model-viewer"
        style={{ border: 'none' }}
        width={width}
        height={height}
        src={src}
      />
    </div>
  );
};

export { S3DV2Wrapper };
