import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Spin } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import { kebabCase } from 'lodash';

import colors from 'helpers/constants/colors';
import zipMedia from 'helpers/utils/zipMedia';

import { MediaItem, SurveyMedia } from '@cpm/scanifly-shared-data';
import { ReactComponent as DownloadCloud } from 'assets/icons/download-cloud.svg';
import styled from 'styled-components';
import { ZIP_MAX_FILE_SIZE } from './constants';
import { openErrorNotification, openSuccessNotification } from './helpers';

export const DownloadButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    margin-bottom: 0;
    margin-left: 1rem;
  }
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center !important;
`;

const ZipButton = ({
  files = [],
  folderName = 'zip',
  buttonText = 'buttonTexts.exportZip',
  secondaryFiles,
  secondaryFolderName,
  isLoading,
  setIsLoading,
}: {
  files: (SurveyMedia | MediaItem)[];
  secondaryFiles?: SurveyMedia[];
  secondaryFolderName?: string;
  folderName: string;
  buttonText?: string;
  isLoading?: boolean;
  setIsLoading?: (loading: boolean) => void;
}) => {
  const [isZipLoading, setIsZipLoading] = useState(false);
  const { t } = useTranslation();

  const handleZip = useCallback(async () => {
    setIsZipLoading(true);
    setIsLoading?.(true);

    const processResult = (zipName: string | false) => {
      if (zipName) {
        openSuccessNotification(zipName);
      } else {
        openErrorNotification();
      }
      setIsZipLoading(false);
      setIsLoading?.(false);
    };

    /**
     * Generates ZIP data
     * @returns zipName if successful, false if not
     */
    const generatedData = async ({
      mediaToZip,
      folderName,
    }: {
      mediaToZip: (SurveyMedia | MediaItem)[];
      folderName: string;
    }): Promise<string | false> => {
      const zipName = kebabCase(`scanifly ${folderName}`);
      const zipSize = files.reduce((totalSize, { size }) => totalSize + (size ?? 0), 0);
      let data;
      if (zipSize < ZIP_MAX_FILE_SIZE) {
        data = await zipMedia(mediaToZip, zipName);
      } else {
        let i = 0;
        let folder: (SurveyMedia | MediaItem)[], folderSize;
        /* eslint-disable no-await-in-loop */
        for (let j = 0; j < Math.ceil(zipSize / ZIP_MAX_FILE_SIZE); j++) {
          folder = [];
          folderSize = 0;
          while (folderSize < ZIP_MAX_FILE_SIZE && mediaToZip[i]) {
            folder.push(mediaToZip[i]);
            folderSize += mediaToZip[i].size ?? 0;
            i++;
          }
          // We would like to serialize zips as to not exceed 2GB
          data = await zipMedia(folder, zipName + '-' + (j + 1));
        } /* eslint-enable no-await-in-loop */
      }
      return data === true ? zipName : false;
    };

    if (secondaryFiles?.length && secondaryFolderName) {
      confirm({
        title: t('ProjectCategory.exportAllPhotos'),
        content: t('ProjectCategory.exportDronePhotosToo'),
        okText: t('Generic.yes'),
        okButtonProps: { style: { background: colors.mainBlue } },
        autoFocusButton: 'ok',
        cancelText: t('Generic.no'),
        maskClosable: true,
        centered: true,
        onOk: async () => {
          setIsZipLoading(true);
          setIsLoading?.(false);
          const zipName = await generatedData({
            mediaToZip: secondaryFiles,
            folderName: secondaryFolderName,
          });
          processResult(zipName);
        },
      });
    }

    const zipName = await generatedData({ mediaToZip: files, folderName });
    processResult(zipName);
  }, [files, secondaryFiles, secondaryFolderName]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StyledButton
      onClick={handleZip}
      className="Button--Blue"
      loading={isZipLoading || isLoading}
      disabled={isZipLoading || !files?.length || isLoading}
      aria-disabled={isZipLoading || !files?.length || isLoading}
      data-testid="export-zip-button"
    >
      <DownloadButtonWrapper
        style={{
          pointerEvents: isZipLoading ? 'none' : 'auto',
          cursor: isZipLoading ? 'not-allowed' : 'pointer',
          opacity: isZipLoading ? 0.6 : 1,
        }}
      >
        {isZipLoading ? (
          <Spin
            size="small"
            style={{ marginTop: '0.5rem' }}
            indicator={
              <div className="ant-spin-dot">
                <span className="ant-spin-dot-item" style={{ backgroundColor: '#ffff' }} />
                <span className="ant-spin-dot-item" style={{ backgroundColor: '#ffff' }} />
                <span className="ant-spin-dot-item" style={{ backgroundColor: '#ffff' }} />
                <span className="ant-spin-dot-item" style={{ backgroundColor: '#ffff' }} />
              </div>
            }
          />
        ) : (
          <DownloadCloud />
        )}
        <p>{buttonText}</p>
      </DownloadButtonWrapper>
    </StyledButton>
  );
};

export default ZipButton;
