import { useSelector } from 'react-redux';
import { matchPath } from 'react-router-dom';

import { FEATURE_LIST, getFeatureAvailability } from '@cpm/scanifly-shared-data';

import { selectChecklistTemplateById } from 'state/slices/checklists';
import { RootState } from 'state/store';

import { DRONE_IMAGES } from 'helpers/constants/categories';
import { PAGE_TITLES } from 'helpers/constants/pageTitles';
import {
  account3dSettingsRoute,
  accountChecklistTemplateManagerRoute,
  accountCompanyChecklistTemplateEditorRoute,
  accountCompanyInfoRoute,
  accountLoginSecurityRoute,
  accountMenuRoute,
  accountPaymentsInvoicesRoute,
  accountProfileRoute,
  creditHistoryAdminQueueRoute,
  designServicesCompanyQueueRoute,
  designServicesProviderQueueRoute,
  designStatusRoute,
  draftNotesRoute,
  draftProjectCategoriesRoute,
  draftProjectCategoryRoute,
  draftProjectConsumptionDataRoute,
  draftProjectCustomerInfoRoute,
  draftProjectDesignsRoute,
  draftProjectInfo,
  draftProjectOverviewRoute,
  draftProjectSiteDataRoute,
  integrationsRoute,
  maintenanceReportRoute,
  newProjectConsumptionDataRoute,
  newProjectCustomerInfoRoute,
  orderDesignRoute,
  projectCategoriesRoute,
  projectCategoryRoute,
  projectChecklistRoute,
  projectConsumptionDataRoute,
  projectCustomerInfoRoute,
  projectDesignsRoute,
  projectInfoRoute,
  projectNotesRoute,
  projectOverviewRoute,
  projectSiteDataRoute,
  projectsListRoute,
  scaniflyAdminAccessTokensRoute,
  scaniflyAdminAccountingRoute,
  scaniflyAdminAccountingTableRoute,
  scaniflyAdminAccountManagerRoute,
  scaniflyAdminAllCreditHistoryRoute,
  scaniflyAdminChecklistManagerRoute,
  scaniflyAdminCompanyCreditHistoryRoute,
  scaniflyAdminCustomerSupportUploadRoute,
  scaniflyAdminDesignServicesQueueRoute,
  scaniflyAdminDraftConsumptionDataRoute,
  scaniflyAdminDraftCustomerInfoRoute,
  scaniflyAdminDraftNotesRoute,
  scaniflyAdminDraftOverviewRoute,
  scaniflyAdminDraftProjectCategoriesRoute,
  scaniflyAdminDraftProjectCategoryRoute,
  scaniflyAdminDraftProjectDesignsRoute,
  scaniflyAdminDraftProjectInfoRoute,
  scaniflyAdminDraftScaniflyInfoRoute,
  scaniflyAdminDraftSiteDataRoute,
  scaniflyAdminMenuRoute,
  scaniflyAdminNewProjectConsumptionDataRoute,
  scaniflyAdminNewProjectCustomerInfoRoute,
  scaniflyAdminNewProjectScaniflyInfoRoute,
  scaniflyAdminProjectCategoriesRoute,
  scaniflyAdminProjectCategoryRoute,
  scaniflyAdminProjectConsumptionDataRoute,
  scaniflyAdminProjectCustomerInfoRoute,
  scaniflyAdminProjectDesignsRoute,
  scaniflyAdminProjectNotesRoute,
  scaniflyAdminProjectOverviewRoute,
  scaniflyAdminProjectProjectInfoRoute,
  scaniflyAdminProjectsByUserRoute,
  scaniflyAdminProjectScaniflyInfoRoute,
  scaniflyAdminProjectsCustomerRoute,
  scaniflyAdminProjectSiteDataRoute,
  scaniflyAdminProjectsRoute,
  scaniflyAdminReferenceMediaRoute,
  scaniflyAdminUsersCustomerRoute,
  scaniflyAdminUsersRoute,
  teamListRoute,
  upgradeDesignRoute,
} from 'helpers/constants/routes';

import usePermissions from './usePermissions';

export const usePageTitle = (pathName: string) => {
  const { company } = useSelector((state: RootState) => state.company);
  const { isScaniflyAdmin } = usePermissions();
  const templateId = pathName.split('/')[3];
  const template = useSelector((state: RootState) =>
    selectChecklistTemplateById(state, templateId)
  );
  const designServicesAccess = getFeatureAvailability(
    isScaniflyAdmin,
    FEATURE_LIST.DESIGN_SERVICES,
    company?.pricingTier
  );

  const isMatch = (path: string) =>
    Boolean(
      matchPath(pathName, {
        path,
        exact: true,
      })
    );

  const { companyName, userName } = useSelector((state: RootState) => state.adminProjects);
  const { companyName: usersCompanyName } = useSelector(
    (state: RootState) => state.adminCompanyUsers
  );

  switch (true) {
    case isMatch(projectsListRoute()):
      return PAGE_TITLES.MY_PROJECTS;
    case isMatch(teamListRoute()):
      return PAGE_TITLES.TEAM_LIST;
    case isMatch(account3dSettingsRoute()):
      return PAGE_TITLES.SCANIFLY_3D_SETTINGS;
    case isMatch(accountMenuRoute()):
      return PAGE_TITLES.ACCOUNT_SETTINGS;
    case isMatch(projectCustomerInfoRoute(':projectId')):
      return PAGE_TITLES.CUSTOMER_INFO;
    case isMatch(projectConsumptionDataRoute(':projectId')):
      return PAGE_TITLES.CONSUMPTION_DATA;
    case isMatch(scaniflyAdminProjectDesignsRoute(':projectId')):
    case isMatch(scaniflyAdminDraftProjectDesignsRoute(':projectId')):
    case isMatch(projectDesignsRoute(':projectId')):
    case isMatch(draftProjectDesignsRoute(':projectId')):
      return PAGE_TITLES.DESIGNS;
    case isMatch(scaniflyAdminProjectCategoryRoute(':projectId', DRONE_IMAGES)):
    case isMatch(scaniflyAdminDraftProjectCategoryRoute(':projectId', DRONE_IMAGES)):
    case isMatch(projectCategoryRoute(':projectId', DRONE_IMAGES)):
    case isMatch(draftProjectCategoryRoute(':projectId', DRONE_IMAGES)):
      return PAGE_TITLES.PROJECT_CATEGORIES;
    case isMatch(projectChecklistRoute(':projectId')):
      return PAGE_TITLES.CHECKLISTS;
    case isMatch(newProjectCustomerInfoRoute()):
    case isMatch(scaniflyAdminNewProjectCustomerInfoRoute()):
      return PAGE_TITLES.NEW_PROJECT_CUSTOMER_INFO;
    case isMatch(newProjectConsumptionDataRoute()):
    case isMatch(scaniflyAdminNewProjectConsumptionDataRoute()):
      return PAGE_TITLES.NEW_PROJECT_CONSUMPTION_DATA;
    case isMatch(accountCompanyInfoRoute()):
      return PAGE_TITLES.ACCOUNT_COMPANY_SETTINGS;
    case isMatch(accountChecklistTemplateManagerRoute()):
      return PAGE_TITLES.CHECKLIST_MANAGER;
    case isMatch(accountCompanyChecklistTemplateEditorRoute(':templateId')):
      return template?.title;
    case isMatch(draftProjectCustomerInfoRoute(':projectId')):
    case isMatch(scaniflyAdminDraftCustomerInfoRoute(':projectId')):
    case isMatch(scaniflyAdminProjectCustomerInfoRoute(':projectId')):
      return PAGE_TITLES.CUSTOMER_INFO;
    case isMatch(draftProjectConsumptionDataRoute(':projectId')):
    case isMatch(scaniflyAdminDraftConsumptionDataRoute(':projectId')):
    case isMatch(scaniflyAdminProjectConsumptionDataRoute(':projectId')):
      return PAGE_TITLES.CONSUMPTION_DATA;
    case isMatch(projectInfoRoute(':projectId')):
    case isMatch(draftProjectInfo(':projectId')):
    case isMatch(scaniflyAdminDraftProjectInfoRoute(':projectId')):
    case isMatch(scaniflyAdminProjectProjectInfoRoute(':projectId')):
      return PAGE_TITLES.PROJECT_INFO;
    case isMatch(scaniflyAdminDraftScaniflyInfoRoute(':projectId')):
    case isMatch(scaniflyAdminProjectScaniflyInfoRoute(':projectId')):
    case isMatch(scaniflyAdminNewProjectScaniflyInfoRoute()):
      return PAGE_TITLES.SCANIFLY_INFO;
    case isMatch(accountProfileRoute()):
      return PAGE_TITLES.USER_PROFILE;
    case isMatch(accountPaymentsInvoicesRoute()):
      return designServicesAccess ? PAGE_TITLES.BILLING_CREDITS : PAGE_TITLES.BILLING;
    case isMatch(accountLoginSecurityRoute()):
      return PAGE_TITLES.ACCOUNT_LOGIN_SECURITY;
    case isMatch(draftNotesRoute(':projectId')):
    case isMatch(projectNotesRoute(':projectId')):
    case isMatch(scaniflyAdminDraftNotesRoute(':projectId')):
    case isMatch(scaniflyAdminProjectNotesRoute(':projectId')):
      return PAGE_TITLES.COMMENTS;
    case isMatch(projectCategoryRoute(':projectId', ':categoryName')):
    case isMatch(draftProjectCategoryRoute(':projectId', ':categoryName')):
    case isMatch(scaniflyAdminDraftProjectCategoryRoute(':projectId', ':categoryName')):
    case isMatch(scaniflyAdminProjectCategoryRoute(':projectId', ':categoryName')):
      return pathName.split('/').pop()?.replace('__', '_');
    case isMatch(projectCategoriesRoute(':projectId')):
    case isMatch(draftProjectCategoriesRoute(':projectId')):
    case isMatch(scaniflyAdminDraftProjectCategoriesRoute(':projectId')):
    case isMatch(scaniflyAdminProjectCategoriesRoute(':projectId')):
      return PAGE_TITLES.ALBUMS;
    case isMatch(scaniflyAdminMenuRoute()):
      return PAGE_TITLES.SCANIFLY_ADMIN_MENU;
    case isMatch(scaniflyAdminAccountManagerRoute()):
      return PAGE_TITLES.SCANIFLY_ADMIN_ACCOUNT_MANAGER;
    case isMatch(scaniflyAdminAccountingRoute()):
    case isMatch(scaniflyAdminAccountingRoute(':id')):
    case isMatch(scaniflyAdminAccountingTableRoute()):
      return PAGE_TITLES.SCANIFLY_ADMIN_ACCOUNTING;
    case isMatch(scaniflyAdminUsersRoute()):
      return PAGE_TITLES.SCANIFLY_ADMIN_USERS;
    case isMatch(scaniflyAdminUsersCustomerRoute(':companyId')):
      return PAGE_TITLES.SCANIFLY_ADMIN_USERS_CUSTOMER + usersCompanyName;
    case isMatch(scaniflyAdminProjectsByUserRoute(':userId')):
      return PAGE_TITLES.SCANIFLY_ADMIN_PROJECT_USER + userName;
    case isMatch(scaniflyAdminCompanyCreditHistoryRoute(':companyId')):
      return PAGE_TITLES.SCANIFLY_ADMIN_COMPANY_CREDIT_HISTORY + usersCompanyName;
    case isMatch(scaniflyAdminAllCreditHistoryRoute()):
      return PAGE_TITLES.SCANIFLY_ADMIN_ALL_CREDIT_HISTORY + usersCompanyName;
    case isMatch(scaniflyAdminReferenceMediaRoute()):
      return PAGE_TITLES.SCANIFLY_ADMIN_REFERENCE_MEDIA;
    case isMatch(scaniflyAdminChecklistManagerRoute(':companyId')):
      return PAGE_TITLES.CHECKLIST_ADMIN_MANAGER;

    case isMatch(draftProjectSiteDataRoute(':projectId')):
    case isMatch(projectSiteDataRoute(':projectId')):
    case isMatch(scaniflyAdminDraftSiteDataRoute(':projectId')):
    case isMatch(scaniflyAdminProjectSiteDataRoute(':projectId')):
      return PAGE_TITLES.SITE_DATA;

    case isMatch(draftProjectOverviewRoute(':projectId')):
    case isMatch(projectOverviewRoute(':projectId')):
    case isMatch(scaniflyAdminDraftOverviewRoute(':projectId')):
    case isMatch(scaniflyAdminProjectOverviewRoute(':projectId')):
      return PAGE_TITLES.PROJECT_OVERVIEW;

    case isMatch(scaniflyAdminCustomerSupportUploadRoute()):
      return PAGE_TITLES.SCANIFLY_ADMIN_CS_UPLOAD;
    case isMatch(scaniflyAdminProjectsRoute()):
      return PAGE_TITLES.SCANIFLY_ADMIN_PROJECTS;
    case isMatch(scaniflyAdminProjectsCustomerRoute(':companyId')):
      return PAGE_TITLES.SCANIFLY_ADMIN_PROJECTS_CUSTOMER + companyName;
    case isMatch(scaniflyAdminAccessTokensRoute()):
      return PAGE_TITLES.SCANIFLY_ADMIN_ACCESS_TOKENS;
    case isMatch(scaniflyAdminDesignServicesQueueRoute()):
      return PAGE_TITLES.SCANIFLY_ADMIN_DESIGN_SERVICES_QUEUE;

    // Design Services:
    case isMatch(designServicesProviderQueueRoute()):
    case isMatch(designServicesCompanyQueueRoute()):
      return PAGE_TITLES.DESIGN_SERVICES_QUEUE;

    case isMatch(creditHistoryAdminQueueRoute()):
      return PAGE_TITLES.ADMIN_CREDIT_HISTORY;

    // @ts-ignore @todo how does this work
    case isMatch(orderDesignRoute(':projectId', ':step')):
      return PAGE_TITLES.ORDER_A_DESIGN;

    case isMatch(designStatusRoute(':projectId', ':step')):
      return PAGE_TITLES.DESIGN_STATUS;

    case isMatch(upgradeDesignRoute(':projectId', ':step')):
      return PAGE_TITLES.DESIGN_STATUS;

    case isMatch(integrationsRoute()):
      return PAGE_TITLES.INTEGRATIONS;

    case isMatch(maintenanceReportRoute(':projectId')):
      return PAGE_TITLES.MAINTENANCE;

    default:
      return '';
  }
};
