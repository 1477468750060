import { Tooltip } from '@cpm/scanifly-shared-components';
import { fontSizes, SortOrder } from '@cpm/scanifly-shared-data';
import { ReactComponent as UpArrow } from 'assets/arrow-up.svg';
import { useState } from 'react';
import styled from 'styled-components';
import { SortOptionTypes } from '../types';

const DownArrow = styled(UpArrow)`
  transform: rotate(180deg);
`;

const SorterWrapper = styled.div`
  cursor: pointer;
  margin: 1.25rem 0;
  font-size: ${fontSizes.small};
  display: flex;
`;

const SorterLabel = styled.p`
  margin-right: 0.75rem;
  margin-bottom: 0;
`;

export const Sorter = ({
  handleSort,
  label,
  sortByField,
  tooltipText,
}: {
  handleSort: ({ sortByField, order }: { sortByField: SortOptionTypes; order: SortOrder }) => void;
  label: string;
  sortByField: SortOptionTypes;
  tooltipText: string;
}) => {
  const [isInAscendingOrder, setIsInAscendingOrder] = useState<boolean>(true);
  const handleClick = () => {
    handleSort({ sortByField, order: isInAscendingOrder ? SortOrder.desc : SortOrder.asc });
    setIsInAscendingOrder((prevState) => !prevState);
  };

  return (
    <SorterWrapper
      role="button"
      onClick={handleClick}
      onKeyDown={() => {}}
      tabIndex={0}
      data-testid={`sort-by-${sortByField}-button`}
    >
      <SorterLabel data-testid="sorter-label">{label}</SorterLabel>
      <Tooltip title={tooltipText} bottom leftPercent={-450}>
        {isInAscendingOrder ? (
          <UpArrow data-testid="up-arrow-icon" />
        ) : (
          <DownArrow data-testid="down-arrow-icon" />
        )}
      </Tooltip>
    </SorterWrapper>
  );
};
