export const NOTIFICATION_TYPES = {
  DUPLICATE: 'duplicate',
  FILE_TYPE: 'fileType',
  MISSING_LOCATION: 'missingLocation',
  NETWORK_CONNECTION: 'networkConnection',
  OFFLINE: 'offline',
  ONLINE: 'online',
  RESTRICTION_FAILED: 'restrictionFailed',
};

export const NOTIFICATION_TITLES = {
  DUPLICATE: 'Duplicate Files Detected',
  FILE_TYPE: 'Wrong File Type Detected',
  MISSING_LOCATION: 'Missing Location Data',
  OFFLINE: 'You are currently offline.',
  ONLINE: 'You are back online!',
  RESTRICTION_FAILED: 'Uh oh!',
  SUCCESS: 'Success!',
};

export const NOTIFICATION_DESCRIPTIONS = {
  DUPLICATE: 'The following files are duplicates. These files have not been uploaded.',
  DUPLICATE_RESTRICTION: 'You cannot add duplicate files.',
  FILE_TYPE_JPG: 'You can only upload jpg files.',
  FILE_TYPE_ALL:
    'You can only upload csv, dfx, dwg, gif, jpg, pdf, png, ppt, pptx, video, webp, and zip files.',
  MISSING_LOCATION: 'The following files were rejected due to missing location data.',
  RESTRICTION_FAILED: 'There was a problem adding your files.',
  SUCCESS: 'Your project was successfully uploaded.',
};

export const SUPPORTED_FILE_TYPES = {
  JPG: ['image/jpeg', 'image/jpg'],
  IMAGE: ['image/jpeg', 'image/jpg', 'image/png'],
  IMAGE_AND_VIDEO: ['image/gif', 'image/jpg', 'image/jpeg', 'image/png', 'image/webp', 'video/*'],
  ALL: [
    'application/octet-stream',
    'application/pdf',
    'application/zip',
    'image/gif',
    'image/jpg',
    'image/jpeg',
    'image/png',
    'image/webp',
    '.ppt',
    '.pptx',
    'text/csv',
    'video/*',
  ],
};

export const UPLOADING = 'Uploading...';
