import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { PlusCircleOutlined, SwapOutlined } from '@ant-design/icons';
import { COMPANY_SIZE, OVERAGE, PAYMENT_TYPE } from '@cpm/scanifly-shared-data';
import { DatePicker, Input, Row } from 'antd';
import { FieldConfig, FieldInputProps, FormikErrors, FormikTouched, FormikValues } from 'formik';
import moment from 'moment';

import { RootState } from 'state/store';

import { CustomSelect, Divider } from 'components';

import { fullMonthDateFormat, shortDateFormat } from 'helpers/utils/dateFormat';
import { renderValidationMessage } from 'helpers/utils/formValidationHelpers';
import { formatUSD } from 'helpers/utils/priceFormatter';

import { COMPANY_SIZE_FILTERS, FORM_CONTROLS } from '../constants';
import { calculateMonthlyFee, calculateProfit, handleNegativeInputValues } from '../helpers';
import { PaymentTypeSelect } from './PaymentTypeSelect';

const AccountingSubscriptionInfoForm = ({
  getFieldProps,
  setFieldValue,
  selectedSubscriptionId,
  setSelectedSubscriptionId,
  setInformationSaved,
  touched,
  errors,
}: {
  touched: FormikTouched<FormikValues>;
  errors: FormikErrors<FormikValues>;
  getFieldProps: (nameOrOptions: string | FieldConfig<any>) => FieldInputProps<any>;
  setFieldValue: (
    field: string,
    value: string | number,
    shouldValidate?: boolean | undefined
  ) => void;
  setInformationSaved: (arg: boolean) => void;
  setSelectedSubscriptionId: (id: string) => void;
  selectedSubscriptionId: string | undefined;
}) => {
  const monthlySubscription = getFieldProps(FORM_CONTROLS.SUBSCRIPTION).value;
  const discount = getFieldProps(FORM_CONTROLS.DISCOUNT).value;
  const paymentType = getFieldProps(FORM_CONTROLS.PAYMENT_TYPE).value;
  const { t } = useTranslation();

  const totalSubscriptionFee = useMemo(
    () =>
      calculateMonthlyFee({
        monthlySubscription,
        discount,
      }),
    [discount, monthlySubscription]
  );

  const yearlyProfit = useMemo(
    () => calculateProfit(monthlySubscription, discount),
    [discount, monthlySubscription]
  );

  const { allSubscriptionsOfAGivenCompany, selectedSubscription, activeSubscription } = useSelector(
    (state: RootState) => state.adminSubscriptions
  );

  useEffect(() => {
    if (
      !selectedSubscription &&
      activeSubscription &&
      selectedSubscriptionId !== '' &&
      activeSubscription.companySize
    ) {
      setFieldValue(FORM_CONTROLS.COMPANY_SIZE, activeSubscription.companySize);
      setFieldValue(
        FORM_CONTROLS.PAYMENT_TYPE,
        activeSubscription?.paymentType ?? PAYMENT_TYPE.monthly
      );
    } else if (
      selectedSubscription &&
      selectedSubscription.companySize &&
      selectedSubscriptionId !== ''
    ) {
      setFieldValue(FORM_CONTROLS.COMPANY_SIZE, selectedSubscription.companySize);
      setFieldValue(
        FORM_CONTROLS.PAYMENT_TYPE,
        selectedSubscription?.paymentType ?? PAYMENT_TYPE.monthly
      );
    } else {
      if (yearlyProfit < 6000) {
        setFieldValue(FORM_CONTROLS.COMPANY_SIZE, COMPANY_SIZE.small);
      }
      if (yearlyProfit >= 6000 && yearlyProfit < 100000) {
        setFieldValue(FORM_CONTROLS.COMPANY_SIZE, COMPANY_SIZE.medium);
      }
      if (yearlyProfit >= 100000) {
        setFieldValue(FORM_CONTROLS.COMPANY_SIZE, COMPANY_SIZE.large);
      }
    }
  }, [
    activeSubscription,
    selectedSubscription,
    selectedSubscriptionId,
    setFieldValue,
    yearlyProfit,
  ]);

  return (
    <div className="Accounting-Form-Subscription">
      <h2 className="Title">{t('AccountingForms.subscriptionInfo')}</h2>
      <Row className="Accounting-Form-CustomSelectRow">
        <div className="FormControl-Wrapper">
          <label htmlFor="accountingSubscriptionVersion">
            {t('AccountingForms.subscriptionVersion')}
          </label>
          <CustomSelect
            {...getFieldProps(FORM_CONTROLS.SUBSCRIPTION_ID)}
            onChange={(option: { value: string; label: string }) => {
              setSelectedSubscriptionId(option.value);
              setFieldValue(FORM_CONTROLS.SUBSCRIPTION_ID, option.value);
              setInformationSaved(false);
            }}
            placeholder={
              activeSubscription ? (
                <div>
                  {moment.utc(activeSubscription?.contractStartDate).format(fullMonthDateFormat)}
                  <SwapOutlined />
                  {moment.utc(activeSubscription?.contractEndDate).format(fullMonthDateFormat)}{' '}
                  {'(current active)'}
                </div>
              ) : (
                t('AccountingForms.noActiveSubscriptions')
              )
            }
            options={[
              ...allSubscriptionsOfAGivenCompany.map((subscription) => ({
                value: subscription.id,
                label: (
                  <div>
                    {moment.utc(subscription?.contractStartDate).format(fullMonthDateFormat)}
                    <SwapOutlined className="Accounting-BetweenIcon" />
                    {moment.utc(subscription?.contractEndDate).format(fullMonthDateFormat)}{' '}
                    {activeSubscription && subscription.id === activeSubscription.id
                      ? '(current active)'
                      : ''}
                  </div>
                ),
              })),
              {
                value: '',
                label: (
                  <div>
                    {' '}
                    <PlusCircleOutlined className="Accounting-Icon" />
                    {t('AccountingForms.createNewSubscription')}
                  </div>
                ),
              },
            ]}
            variant="filter"
            id="accountingSubscriptionVersion"
            tall
          />
        </div>
      </Row>
      <PaymentTypeSelect
        setFieldValue={setFieldValue}
        getFieldProps={getFieldProps}
        field="paymentType"
        formControl={FORM_CONTROLS.PAYMENT_TYPE}
      />
      <Row className="Accounting-Form-Row">
        <div className="FormControl-Wrapper">
          <label htmlFor="accountingMonthlySub">
            <span className="Accounting-Form-Asterisk">* </span>
            {paymentType === PAYMENT_TYPE.upfront
              ? t('AccountingForms.totalSubscription')
              : t('AccountingForms.monthlySubscription')}
            <div className="Form-Error">
              {renderValidationMessage(touched, errors, FORM_CONTROLS.SUBSCRIPTION)}
            </div>
          </label>
          <Input
            {...getFieldProps(FORM_CONTROLS.SUBSCRIPTION)}
            type="number"
            min="0"
            prefix="$"
            id="accountingMonthlySub"
            placeholder="0"
            onChange={(ev) => handleNegativeInputValues(ev, setFieldValue, 'SUBSCRIPTION')}
          />
          <label htmlFor="accountingDiscount">
            <span className="Accounting-Form-Asterisk">* </span>
            {t('AccountingForms.discount')}
            <div className="Form-Error">
              {renderValidationMessage(touched, errors, FORM_CONTROLS.DISCOUNT)}
            </div>
          </label>
          <Input
            {...getFieldProps(FORM_CONTROLS.DISCOUNT)}
            type="number"
            min="0"
            suffix="%"
            id="accountingDiscount"
            placeholder="0"
            onChange={(ev) => handleNegativeInputValues(ev, setFieldValue, 'DISCOUNT')}
          />
        </div>
      </Row>
      <Row className="Accounting-Form-Row">
        <div className="FormControl-Wrapper">
          <label htmlFor="smallProjects">
            <span className="Accounting-Form-Asterisk">* </span>
            {t('AccountingForms.smallProjectCount')}
            <div className="Form-Error">
              {renderValidationMessage(touched, errors, FORM_CONTROLS.PROJECT_QUOTA_SM)}
            </div>
          </label>
          <Input
            {...getFieldProps(FORM_CONTROLS.PROJECT_QUOTA_SM)}
            type="number"
            min="0"
            placeholder="0"
            id="smallProjects"
            onChange={(ev) => handleNegativeInputValues(ev, setFieldValue, 'PROJECT_QUOTA_SM')}
          />
          <label htmlFor="largeProjects">
            <span className="Accounting-Form-Asterisk">* </span>
            {t('AccountingForms.largeProjectCount')}
            <div className="Form-Error">
              {renderValidationMessage(touched, errors, FORM_CONTROLS.PROJECT_QUOTA_LG)}
            </div>
          </label>
          <Input
            {...getFieldProps(FORM_CONTROLS.PROJECT_QUOTA_LG)}
            type="number"
            min="0"
            placeholder="0"
            id="largeProjects"
            onChange={(ev) => handleNegativeInputValues(ev, setFieldValue, 'PROJECT_QUOTA_LG')}
          />
        </div>
      </Row>
      <Row className="Accounting-Form-Row">
        <label htmlFor="smallOverage">{t('AccountingForms.smallAdditionalFee')}</label>
        <p id="smallOverage">${selectedSubscription?.smallOverage ?? OVERAGE.small}</p>
        <label htmlFor="largeOverage">{t('AccountingForms.largeAdditionalFee')}</label>
        <p id="largeOverage">${selectedSubscription?.largeOverage ?? OVERAGE.large}</p>
      </Row>
      <Row className="Accounting-Form-Row">
        <div className="FormControl-Wrapper">
          <label htmlFor="accountingContractStart">
            <span className="Accounting-Form-Asterisk">* </span>
            {t('AccountingForms.contractStartDate')}
            <div className="Form-Error">
              {renderValidationMessage(touched, errors, FORM_CONTROLS.CONTRACT_START)}
            </div>
          </label>
          <DatePicker
            {...getFieldProps(FORM_CONTROLS.CONTRACT_START)}
            format={shortDateFormat}
            onChange={(value) => {
              setFieldValue(
                FORM_CONTROLS.CONTRACT_START,
                value?.utc().startOf('day') as unknown as string
              );
            }}
            id="accountingContractStart"
          />
        </div>
      </Row>
      <Row className="Accounting-Form-Row">
        <div className="FormControl-Wrapper">
          <label htmlFor="accountingContractEnd">
            <span className="Accounting-Form-Asterisk">* </span>
            {t('AccountingForms.contractEndDate')}
            <div className="Form-Error">
              {renderValidationMessage(touched, errors, FORM_CONTROLS.CONTRACT_END)}
            </div>
          </label>
          <DatePicker
            {...getFieldProps(FORM_CONTROLS.CONTRACT_END)}
            format={shortDateFormat}
            onChange={(value) =>
              setFieldValue(
                FORM_CONTROLS.CONTRACT_END,
                value?.utc().endOf('day') as unknown as string
              )
            }
            id="accountingContractEnd"
          />
        </div>
      </Row>
      <Row className="Accounting-Form-CustomSelectRow">
        <div className="FormControl-Wrapper">
          <label htmlFor="companySize">{t('AccountingForms.companySize')}</label>
          <CustomSelect
            {...getFieldProps(FORM_CONTROLS.COMPANY_SIZE)}
            onChange={(option: { value: string; label: string }) => {
              setFieldValue(FORM_CONTROLS.COMPANY_SIZE, option.value);
            }}
            placeholder="Select Company Size"
            options={COMPANY_SIZE_FILTERS.map((type) => ({ value: type.value, label: type.text }))}
            variant="filter"
            id="companySize"
            tall
          />
        </div>
      </Row>
      <div className="Accounting-Form-Total-Fee">
        <p>{t('AccountingForms.totalSubscriptionFee')}</p>
        <p>{formatUSD(totalSubscriptionFee)}</p>
      </div>
      <Divider />
    </div>
  );
};

export default AccountingSubscriptionInfoForm;
