import { DragDrop } from '@uppy/react';
import { MouseEvent, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt, useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';

import {
  FEATURE_LIST,
  formatFileName,
  getFeatureAvailability,
  MediaItem,
  OVERAGE,
  PROJECT_TYPES,
  PROJECT_STATUS as SHARED_PROJECT_STATUS,
} from '@cpm/scanifly-shared-data';
import { UppyFile } from '@uppy/core';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import { Button, Checkbox, RadioChangeEvent, Spin, Tooltip } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import confirm from 'antd/lib/modal/confirm';
import { ReactComponent as DownloadCloud } from 'assets/icons/download-cloud.svg';
import cn from 'classnames';
import { MAP_DEFAULTS, MAP_ZOOM } from 'screens/MapWrapper/constants';
import { BoundingBox } from 'types';
import { BoundingBoxFeature } from 'types/BoundingBox';

import { companyActiveSubscriptionRequested } from 'state/slices/admin/adminSubscriptionsSlice';
import { boundariesRequested, resetBoundaries } from 'state/slices/boundariesSlice';
import { companySubscriptionInfoRequested, userCompanyRequested } from 'state/slices/companySlice';
import { resetServiceRequest } from 'state/slices/designServices/designServiceRequestsSlice';
import { selectAlbumView, setAlbumView } from 'state/slices/localPreferencesSlice';
import {
  projectMediasDeleted,
  projectMediasRequested,
  resetIsMediasDeletionSuccessful,
  resetProjectMedias,
} from 'state/slices/mediasSlice';
import {
  projectRequested,
  resetProject,
  updateProjectStatusById,
  userSuccessfullyUploadedProject,
  userUploadedProject,
} from 'state/slices/projectSlice';
import { AppDispatch, RootState } from 'state/store';

import { GoBackButton, Button as IconButton, ModalContext, ZipButton } from 'components';
import { MediaCarouselContext, MediaCarouselProvider } from 'components/MediaCarousel';
import type { ModalContextType } from 'components/Modal/types';

import {
  ALL_PHOTOS_NAME,
  ALL_PHOTOS_URL_ENCODED,
  ARCHIVE_NAME,
  DRONE_IMAGES,
} from 'helpers/constants/categories';
import colors from 'helpers/constants/colors';
import { CONFIRM_PROPS } from 'helpers/constants/modals';
import { MODES } from 'helpers/constants/projectModes';
import { PROJECT_STATUSES } from 'helpers/constants/projectStatuses';
import {
  draftNotesRoute,
  draftProjectCategoryRoute,
  projectNotesRoute,
  rootRoute,
  scaniflyAdminCustomerSupportUploadRoute,
  scaniflyAdminDraftNotesRoute,
  scaniflyAdminDraftProjectCategoryRoute,
  scaniflyAdminProjectNotesRoute,
} from 'helpers/constants/routes';
import usePermissions from 'helpers/hooks/usePermissions';
import { usePreviewSelect } from 'helpers/hooks/usePreviewSelect';
import usePrevious from 'helpers/hooks/usePrevious';
import useToggle from 'helpers/hooks/useToggle';
import useUploadManager from 'helpers/hooks/useUploadManager';
import { validators } from 'helpers/utils';
import { mapAreaToProjectType } from 'helpers/utils/mapAreaToProjectType';
import { squareMetersToFeetConverter } from 'helpers/utils/metricConversions';
import { pluralize } from 'helpers/utils/pluralize';
import { FileMetaData } from 'helpers/utils/uppy/types';

import { ReactComponent as CategoryIcon } from 'assets/icons/category-icon.svg';
import { ReactComponent as QuestionMarkIcon } from 'assets/icons/question-mark-icon.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash-icon.svg';

import { openErrorNotification } from 'components/ZipButton/helpers';
import { DownloadButtonWrapper } from 'components/ZipButton/ZipButton';
import fontWeights from 'helpers/constants/fontWeights';
import { openNotification } from 'helpers/utils/openNotification';
import { getProjectMediaCategories } from 'state/slices/projectMediaCategories';
import styled from 'styled-components';
import { ToggleLegacyViewButton } from '../AlbumView/ToggleLegacyViewButton/ToggleLegacyViewButton';
import { formatNameFromPath } from '../helpers';
import { AutomatedServiceNotificationBox } from './AutomatedServiceNotificationBox/AutomatedServiceNotificationBox';
import { UPLOADING } from './constants';
import { DistantPhotoReview } from './DistantPhotoReview';
import DroneDataScore from './DroneDataScore/DroneDataScore';
import {
  getGeolocationFromMeta,
  getSuccessfulUploadRedirectionRoute,
  handleConfirmMediaDeletion,
  handleOpenCancelConfirm,
  isValidGeolocation,
  openUploadSuccessNotification,
  selectDefaultBoundingBoxType,
} from './helpers';
import NotificationBox from './NotificationBox/NotificationBox';
import './ProjectCategory.scss';
import ProjectCategoryMap from './ProjectCategoryMap';
import { ZOOM_BY_PROJECT } from './ProjectCategoryMap/constants';
import {
  createBoundingBoxFeature,
  getBoundingBox,
  getBoundsForMarkers,
  getCoordsForBoundingBox,
  mapFeatureToBoundingBox,
} from './ProjectCategoryMap/helpers';
import ProjectTypeFilter from './ProjectTypeFilter/ProjectTypeFilter';
import RetryAlert from './RetryAlert/RetryAlert';
import { Subtitle } from './styledComponents';
import ThumbnailList from './ThumbnailList';
import { Metadata } from './types';
import { UploadOverlay } from './UploadOverlay';
import useUploadProjectValidations from './useUploadProjectValidations';
import ViewSwitchToggle from './ViewSwitchToggle/ViewSwitchToggle';

export const StyledDownloadLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.mainBlue};
  color: ${colors.white};
  border: 0.1rem solid #cfcfcf;
  border-radius: 0.8rem;
  height: 3.8rem;
  padding: 0 1.6rem;
  font-weight: ${fontWeights.semiBold};
  font-size: 1.4rem;

  &:hover {
    color: ${colors.white};
  }

  svg {
    margin: 0;
  }
`;

type ProjectCategoryProps = { isAdminRoute: boolean };
function ProjectCategory({ isAdminRoute = false }: ProjectCategoryProps) {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { mediaCarouselRef, setActiveIndex } = useContext(MediaCarouselContext);
  const selectedAlbumView = useSelector(selectAlbumView);

  const [metadata, setMetadata] = useState<Metadata[]>([]);
  const [checkedThumbnails, setCheckedThumbnails] = useState<string[]>([]);
  const [popup, setPopup] = useState<Metadata | null>(null);
  const [uploadInProgress, setUploadInProgress] = useState<boolean>(false);
  const [boundaries, setBoundaries] = useState<{
    projectId: string;
    boundingBox: BoundingBox;
  } | null>(null);
  const [projectType, setProjectType] = useState<PROJECT_TYPES | null>(null);
  const [features, setFeatures] = useState<{
    type: string;
    features: [BoundingBoxFeature];
  } | null>(null);
  const [droneImagesMapViewport, setDroneImagesMapViewport] = useState(MAP_DEFAULTS);
  const [isImageUploadFailed, setIsImageUploadFailed] = useState<boolean>(false);
  const [isCheckedAll, toggleIsCheckedAll] = useToggle(false);
  const [areaInSquareFeet, setAreaInSquareFeet] = useState<number>(0);
  const [areaInSquareMeter, setAreaInSquareMeter] = useState<number>(0);
  const [mustUpdateMap, setMustUpdateMap] = useState<boolean>(false);

  const setIsListView = useCallback(
    (isListViewEnabled: boolean) => {
      dispatch(setAlbumView(isListViewEnabled ? 'list' : 'thumbnail'));
    },
    [dispatch]
  );

  const prevMetdataLength = usePrevious<number>({ value: metadata.length });

  const { projectId, categoryName } = useParams<{ projectId: string; categoryName: string }>();
  const { hasUploadPermissions, isScaniflyAdmin, isDesignServiceProvider } = usePermissions();
  const { currentUser } = useSelector((state: RootState) => state.users);
  const { boundaries: savedBoundaries } = useSelector((state: RootState) => state.boundaries);
  const { projectCategories } = useSelector((state: RootState) => state.projectCategories);
  const { project, isProjectUploadedSuccessfully, isProjectRequestedLoading } = useSelector(
    (state: RootState) => state.project
  );
  const { projectMedias, isProjectMediasLoading, isMediasDeletionSuccessful } = useSelector(
    (state: RootState) => state.medias
  );

  const { company, companySubscriptionInfo } = useSelector((state: RootState) => state.company);

  const { activeSubscription } = useSelector((state: RootState) => state.adminSubscriptions);

  const category = formatNameFromPath(categoryName);
  const companyId = company?.id;

  const categoryObject = useMemo(
    () => projectCategories.find((item) => item.categoryName === category),
    [category, projectCategories]
  );

  const categoryId = useMemo(() => categoryObject?.id, [categoryObject]);

  // Media can't be finished loading if the category is unknown or if the
  // category is known and the media count does not match
  const isMediaLoading = useMemo(
    () =>
      !categoryObject ||
      isProjectMediasLoading ||
      ((categoryObject?.uploadedMediasCount ?? 0) > 0 && projectMedias.length === 0),
    [isProjectMediasLoading, categoryObject, projectMedias?.length]
  );

  useEffect(() => {
    if (projectId && projectCategories && projectCategories.length < 1) {
      dispatch(getProjectMediaCategories(projectId));
    }
  }, [dispatch, projectId, projectCategories]);

  const mountedRef = useRef<boolean>();
  const categoryIdRef = useRef(categoryId);
  const boundariesRef = useRef(boundaries);
  const projectTypeRef = useRef(projectType);

  const isRemoteDesignStarted = project?.status === SHARED_PROJECT_STATUS.remoteDesignStarted;
  const isNoFlight = project?.status === SHARED_PROJECT_STATUS.noFlight;
  const isProjectLoaded = project !== null;
  const isDraft = project?.statusDescription === PROJECT_STATUSES.DRAFT;
  const isDroneImagesUploaded = project?.status === SHARED_PROJECT_STATUS.droneImagesUploaded;
  const isDraftOrRemote = isDraft || isRemoteDesignStarted || isNoFlight;
  const isDroneImages = category === DRONE_IMAGES;
  const isDroneImagesDraft = isDraftOrRemote && isDroneImages;
  const shouldDisplayNoFlightButton = isDroneImagesDraft && !isNoFlight && !isDroneImagesUploaded;

  const droneDataScoreAvailabilityForPricingTier = getFeatureAvailability(
    isScaniflyAdmin,
    FEATURE_LIST.DRONE_DATA_SCORE,
    company?.pricingTier
  );
  const notesAccess = getFeatureAvailability(
    isScaniflyAdmin,
    FEATURE_LIST.COMMENTS_MENTIONS,
    company?.pricingTier
  );
  const designServicesAccess = getFeatureAvailability(
    false,
    FEATURE_LIST.DESIGN_SERVICES,
    company?.pricingTier
  );

  // @ts-ignore don't have time to add types to helpers.js
  const defaultProjectType = selectDefaultBoundingBoxType(activeSubscription);

  const { displayDeleteModal } = useContext<ModalContextType>(ModalContext);

  const updateProjectStatusToNoFlight = useCallback(async () => {
    return await dispatch(updateProjectStatusById(projectId, SHARED_PROJECT_STATUS.noFlight));
  }, [dispatch, projectId]);

  const indicateNoFlightOnClick = useCallback(() => {
    displayDeleteModal({
      title: t('NoFlightModal.title'),
      description: t('NoFlightModal.description'),
      actionButtonOnClick: updateProjectStatusToNoFlight,
      actionButtonLabel: t('buttonTexts.confirm'),
    });
  }, [updateProjectStatusToNoFlight, displayDeleteModal, t]);

  const uploadingAllowed = useMemo(() => {
    if (!isDroneImages && category && category !== ALL_PHOTOS_NAME && category !== ARCHIVE_NAME) {
      return true;
    }
    if (isDroneImagesDraft) {
      return true;
    }
    return false;
  }, [isDroneImages, category, isDroneImagesDraft]);

  // The difference between isNewProject & isDraft:
  // New Projects do not have a projectId yet because they haven't been created (no projectId in params, also no status)
  // Draft projects do have a projectId, but they don't have uploaded drone images.
  const { isExact: isNewProject } = {
    ...useRouteMatch({
      path: !isAdminRoute
        ? draftProjectCategoryRoute(projectId, category)
        : scaniflyAdminDraftProjectCategoryRoute(projectId, category),
    }),
  };

  useEffect(() => {
    if (projectId) {
      dispatch(projectRequested(projectId));
      dispatch(getProjectMediaCategories(projectId));
      if (isDroneImages && !isDraftOrRemote) {
        dispatch(boundariesRequested(projectId));
      }
      dispatch(
        projectMediasRequested({
          projectId: projectId,
          mediaCategoryName: category,
        })
      );
    }
  }, [projectId, isNewProject, category, isDraftOrRemote, isDroneImages, dispatch]);

  const updateOrCreateBoundaries = useCallback(
    (boundingBox: BoundingBoxFeature, isResizing = false) => {
      const bounds: { boundingBox: BoundingBox; projectId: string } = mapFeatureToBoundingBox(
        boundingBox,
        projectId
      );
      const { area } = bounds.boundingBox;
      if (boundaries && isResizing) {
        const newProjectType = mapAreaToProjectType(area);
        if (newProjectType !== projectType) {
          setProjectType(newProjectType);
        }
      }
      setAreaInSquareMeter(area);
      setAreaInSquareFeet(squareMetersToFeetConverter(area));
      setBoundaries(bounds);
    },
    [boundaries, projectId, projectType]
  );

  const updateBoundingBox = useCallback(
    (type: PROJECT_TYPES | null) => {
      if (!type) {
        return;
      }
      setDroneImagesMapViewport((prevState) => {
        const { latitude, longitude } = prevState;
        if (latitude || project?.geolocation) {
          const bounds = { latitude, longitude };
          const boundingBox = getBoundingBox(
            // @ts-ignore
            getCoordsForBoundingBox(bounds, { type, geolocation: project?.geolocation })
          );
          setFeatures({
            type: 'FeatureCollection',
            features: [boundingBox],
          });
          updateOrCreateBoundaries(boundingBox);
        }
        return {
          ...prevState,
          latitude: latitude ?? project?.geolocation.latitude,
          longitude: longitude ?? project?.geolocation.longitude,
          // @ts-ignore
          zoom: ZOOM_BY_PROJECT[type] || MAP_ZOOM,
        };
      });
    },
    [project?.geolocation, updateOrCreateBoundaries]
  );

  /**
   * Update map viewport with new metadata from files, then update bounding box
   */
  const updateMapByMetadata = useCallback(() => {
    if (metadata?.find((item: { geolocation: any }) => item.geolocation)) {
      const bounds = getBoundsForMarkers(metadata);
      setDroneImagesMapViewport((prevState) => ({
        ...prevState,
        ...bounds,
      }));
      if (projectTypeRef.current === null) {
        setProjectType(defaultProjectType);
      }
      updateBoundingBox(projectTypeRef.current ?? defaultProjectType);
    } else if (isDroneImages && !isDraftOrRemote) {
      // TODO: Remove when we have added BE support for parsing geolocation for drone image markers
      setDroneImagesMapViewport((prevState) => ({
        ...prevState,
        latitude: project?.geolocation.latitude,
        longitude: project?.geolocation.longitude,
        // @ts-ignore
        zoom: ZOOM_BY_PROJECT[project?.type ?? defaultProjectType] || MAP_ZOOM,
      }));
      if (projectTypeRef.current === null) {
        setProjectType(defaultProjectType);
      }
      updateBoundingBox(projectTypeRef.current ?? defaultProjectType);
    }
  }, [
    defaultProjectType,
    isDraftOrRemote,
    isDroneImages,
    metadata,
    project?.geolocation,
    project?.type,
    updateBoundingBox,
  ]);

  const handleProjectTypeChange = useCallback(
    (event: RadioChangeEvent | null) => {
      if (!event && projectType !== null) {
        return;
      }
      const type: PROJECT_TYPES = event ? event.target.value : defaultProjectType;
      setProjectType(type);
      updateBoundingBox(type);
    },
    [defaultProjectType, projectType, updateBoundingBox]
  );

  /**
   * Needed so we can update map/bounding box when files added/removeed
   * without relying on the `filesToRender` useEffect. Because it fires
   * a hundred times as thumbnails are generated and we don't want to make the map
   * re-render that many times
   */
  const onFilesPicked = useCallback(() => {
    setMustUpdateMap(true);
  }, []);

  const onDroneImagesUploaded = useCallback(() => {
    if (projectTypeRef.current) {
      dispatch(
        userUploadedProject({
          projectId,
          projectType: projectTypeRef.current,
          boundaries: boundariesRef.current,
          mode: MODES.NORMAL,
        })
      );
    }
  }, [dispatch, projectId]);

  /**
   * If project is an admin upload, upload photos under S3 bucket for the submittedFor user.
   * If not, upload photos under S3 bucket for the currently logged in user.
   */
  const { addedFiles, failedFiles, retryFailedUploads, uploadProgressMap, uppy } = useUploadManager(
    {
      isDroneImages,
      projectMedias,
      projectId,
      projectGeolocation: project?.geolocation,
      categoryId: categoryId ?? '',
      categoryName: category,
      onDroneImagesUploaded,
      onFilesPicked,
      onFilesRemoved: onFilesPicked,
      userId: project?.submittedFor ? project.submittedFor.id : currentUser?.id ?? '',
    }
  );

  const { isSaveAllowed, errorMessage } = useUploadProjectValidations({
    projectMedias: [...addedFiles, ...projectMedias],
    projectType,
    boundaries,
  });

  /**
   * Files were previously sorted alphabetically which caused
   * inconsistent placement of the currently uploading media.
   *
   * EX: if the album name started with a character before `u` the uploading items
   * would be at the end. However if it was after `u` the uploading items would
   * display at the top of the order.
   *
   * Media is sorted on the backend prior to sending to the
   * front end so we shouldn't need to worry about sort order here.
   */
  const filesToRender: (MediaItem | UppyFile<Record<string, unknown>, Record<string, unknown>>)[] =
    useMemo(() => {
      return [...projectMedias, ...addedFiles];
    }, [addedFiles, projectMedias]);

  const { selectedImage, setSelectedImage } = usePreviewSelect({
    mediaItems: filesToRender,
    isMediaLoading,
  });

  useEffect(() => {
    const data: Metadata[] = [];
    for (const file of filesToRender) {
      const mediaItem = file as MediaItem;
      const uppyFile = file as UppyFile;
      if (
        !validators.isVideo(file) &&
        (isValidGeolocation(mediaItem?.meta?.geolocation) ||
          isValidGeolocation(mediaItem.geolocation))
      ) {
        const fileDisplayName = mediaItem?.displayValue ?? formatFileName({ media: mediaItem });
        data.push({
          id: file.id,
          isUploaded: !!mediaItem.projectId,
          data: uppyFile.data,
          fileName: !mediaItem.fileId && !isDroneImages ? UPLOADING : fileDisplayName,
          geolocation: uppyFile.meta?.geolocation
            ? getGeolocationFromMeta(uppyFile.meta.geolocation)
            : mediaItem.geolocation!,
          thumbnailUrl: mediaItem.thumbnailUrl ?? '',
        });
      }
    }
    setMetadata(data);
    setMustUpdateMap(true);
  }, [filesToRender, isDroneImages, setMetadata, isProjectMediasLoading, isDroneImagesDraft]);

  const imagePreviews = useMemo(() => {
    const previewUpdates: { [key: string]: string } = {};
    for (const file of filesToRender) {
      // file.preview is the local URL generated by the Uppy Thumbnail generator
      // file.data is the local raw data of the file
      // file.thumbnailUrl is the URL of the thumbnail generated by the backend
      if ((file as UppyFile)?.preview) {
        previewUpdates[file.id] = (file as UppyFile).preview ?? '';
      } else if ((file as MediaItem).thumbnailUrl) {
        previewUpdates[file.id] = (file as MediaItem)?.thumbnailUrl ?? '';
      } else if ((file as UppyFile)?.data) {
        // If there is no preview we probably don't want to display the full size image in the thumbnail
        // previewUpdates[file.id] = URL.createObjectURL(file.data);
      }
    }
    return previewUpdates;
  }, [filesToRender]);

  useEffect(() => {
    dispatch(userCompanyRequested());
  }, [dispatch]);

  useEffect(() => {
    if (failedFiles.length) {
      setIsImageUploadFailed(true);
    }
  }, [failedFiles]);

  /**
   * Since `addedFiles` are managed in the useUploadManager hook we need to be able to update the map
   * when files are added/removed rather than in the useEffect for `filesToRender` because that useEffect will fire
   * every time a thumbnail generates.
   *
   * Setting `mustUpdateMap` causes this to fire `updateMapByMetadata` which will update the drone image view port
   * and then call `updateBoundingBox` so we get the map/bbox updated correctly and as little as possible
   */
  useEffect(() => {
    if (mustUpdateMap && metadata.length !== prevMetdataLength && !uploadInProgress) {
      setMustUpdateMap(false);
      updateMapByMetadata();
    }
  }, [metadata.length, mustUpdateMap, prevMetdataLength, uploadInProgress, updateMapByMetadata]);

  useEffect(() => {
    if (companyId && !isScaniflyAdmin && !isDesignServiceProvider) {
      dispatch(companyActiveSubscriptionRequested(companyId));
    }
  }, [companyId, isScaniflyAdmin, dispatch, isDesignServiceProvider]);

  useEffect(() => {
    boundariesRef.current = boundaries;
    categoryIdRef.current = categoryId;
    projectTypeRef.current = projectType;
  }, [boundaries, categoryId, projectType]);

  useEffect(() => {
    if (savedBoundaries && !isDraftOrRemote && isProjectLoaded) {
      setFeatures(createBoundingBoxFeature(savedBoundaries));
      setBoundaries(savedBoundaries);
    }
  }, [isDraftOrRemote, isProjectLoaded, savedBoundaries]);

  useEffect(() => {
    if (isProjectUploadedSuccessfully) {
      setUploadInProgress(false);
      dispatch(userSuccessfullyUploadedProject());
      dispatch(resetProject());
      openUploadSuccessNotification();
      history.push(
        getSuccessfulUploadRedirectionRoute(
          designServicesAccess,
          isAdminRoute,
          companySubscriptionInfo,
          projectId,
          projectType
        )
      );
    }
  }, [
    dispatch,
    history,
    isProjectUploadedSuccessfully,
    projectId,
    isAdminRoute,
    companySubscriptionInfo,
    projectType,
    designServicesAccess,
  ]);

  useEffect(() => {
    if (isMediasDeletionSuccessful) {
      dispatch(resetIsMediasDeletionSuccessful());
      dispatch(
        projectMediasRequested({
          projectId,
          mediaCategoryName: category,
        })
      );
    }
  }, [isDroneImages, projectId, category, isMediasDeletionSuccessful, dispatch]);

  useEffect(() => {
    if (isDroneImagesDraft) {
      if (!mountedRef.current) {
        mountedRef.current = true;
      } else {
        window.onbeforeunload = addedFiles.length ? () => true : null;
      }
      return () => {
        window.onbeforeunload = null;
        dispatch(resetProjectMedias());
        dispatch(resetBoundaries());
      };
    }
    if (company?.id && !isDesignServiceProvider) {
      dispatch(companySubscriptionInfoRequested(company?.id));
    }
    // We only want this to run once on mount and once on unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let redirect;
    if (category !== DRONE_IMAGES) {
      if (
        projectCategories.length &&
        !projectCategories.find((item) => item.categoryName.includes(category))
      ) {
        // unknown project category redirect to All Photos, fallback to root route
        const allPhotos = projectCategories.find((item) => item.categoryName === ALL_PHOTOS_NAME);
        if (allPhotos) {
          const newPathname = location.pathname.replace(categoryName, ALL_PHOTOS_URL_ENCODED);
          const updatedUrl = `${newPathname}${location.search}${location.hash}`;
          history.replace(updatedUrl);
          history.push(updatedUrl);
        } else {
          redirect = true;
        }
      }
    }

    if (redirect) {
      history.push(rootRoute());
    }
  }, [category, history, projectCategories, location, categoryName]);

  const handleCheckAll = () => {
    if (isCheckedAll) {
      setCheckedThumbnails([]);
    } else {
      setCheckedThumbnails(
        isDroneImages
          ? [...addedFiles, ...projectMedias].map((file) => file.id)
          : projectMedias.map((file) => file.id)
      );
    }
    toggleIsCheckedAll();
  };

  const handleCheck = useCallback(
    (event: CheckboxChangeEvent, media: MediaItem) => {
      const checked = event.target.checked;
      if (isCheckedAll || (checked && checkedThumbnails.length === filesToRender.length - 1)) {
        toggleIsCheckedAll();
      }
      setCheckedThumbnails(
        checked
          ? [...checkedThumbnails, media.id]
          : checkedThumbnails.filter((id) => id !== media.id)
      );
    },
    [checkedThumbnails, isCheckedAll, filesToRender, toggleIsCheckedAll]
  );

  const handleDeleteThumbnails = useCallback(() => {
    checkedThumbnails.map((id) => uppy.removeFile(id));
    const projectMediasToDelete: string[] = [];
    projectMedias.forEach((media) => {
      if (checkedThumbnails.includes(media.id)) {
        projectMediasToDelete.push(media.id);
      }
    });

    if (isCheckedAll) {
      toggleIsCheckedAll();
      setPopup(null);
      setSelectedImage(null);
      resetBoundariesData();
    } else {
      if (checkedThumbnails.includes(popup?.id ?? '')) {
        setPopup(null);
      }
      if (checkedThumbnails.includes(selectedImage?.id ?? '')) {
        setSelectedImage(null);
      }
      if (boundaries && checkedThumbnails.length === metadata.length) {
        resetBoundariesData();
      }
    }
    setMetadata(metadata.filter((meta) => !checkedThumbnails.includes(meta.id)));
    setCheckedThumbnails([]);

    dispatch(
      projectMediasDeleted({
        medias: { medias: projectMediasToDelete },
        mediaCategoryName: category,
      })
    );
  }, [
    boundaries,
    checkedThumbnails,
    category,
    dispatch,
    isCheckedAll,
    metadata,
    popup?.id,
    projectMedias,
    selectedImage?.id,
    toggleIsCheckedAll,
    uppy,
    setSelectedImage,
  ]);

  /**
   * Called by <DistantPhotoReview> after user confirms which photos
   * to remove from Uppy
   */
  const handleRemoveDistantPhotos = useCallback(
    (distantPhotosToRemove: UppyFile[]) => {
      const distantPhotosToRemoveIds = distantPhotosToRemove.map(({ id }) => {
        uppy.removeFile(id);
        return id;
      });

      if (distantPhotosToRemoveIds.length === metadata.length) {
        resetBoundariesData();
      }
      setMustUpdateMap(true);
    },
    [metadata.length, uppy]
  );

  const handleSelectImage = useCallback(
    ({
      mediaItem,
      evt,
      index,
    }: {
      mediaItem: MediaItem;
      evt: MouseEvent<HTMLElement> | undefined;
      index: number;
    }) => {
      if (popup) {
        setPopup(null);
      }
      if (!mediaItem || selectedImage?.id === mediaItem?.id) {
        // @ts-ignore
        evt?.target?.blur();
        return setSelectedImage(null);
      }
      setActiveIndex(index);
      mediaCarouselRef?.current?.goTo(index);
      setSelectedImage(mediaItem);
    },
    [mediaCarouselRef, popup, selectedImage?.id, setSelectedImage] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleRenderMap = useCallback(
    (image: MediaItem, event: any) => {
      if (selectedImage) {
        setSelectedImage(null);
      }
      if (popup?.id === image.id) {
        event.currentTarget.blur();
        return setPopup(null);
      }
      setPopup(metadata.find((media) => media.id === image.id) ?? null);
    },
    [metadata, popup?.id, selectedImage, setSelectedImage]
  );

  const getDraftNotesRoute = () => {
    return isAdminRoute ? scaniflyAdminDraftNotesRoute(projectId) : draftNotesRoute(projectId);
  };

  const getProjectNotesRoute = () => {
    return isAdminRoute ? scaniflyAdminProjectNotesRoute(projectId) : projectNotesRoute(projectId);
  };

  const handleNext = () => {
    history.push(isDraftOrRemote ? getDraftNotesRoute() : getProjectNotesRoute());
  };

  const handleExit = () => {
    history.push(isAdminRoute ? scaniflyAdminCustomerSupportUploadRoute() : rootRoute());
  };

  const shouldDisplayQuotaErrorMessage = useMemo(
    () =>
      !isScaniflyAdmin &&
      ((!activeSubscription?.largeProjectQuota && projectType === PROJECT_TYPES.LARGE) ||
        (!activeSubscription?.smallProjectQuota && projectType === PROJECT_TYPES.SMALL)),
    [
      isScaniflyAdmin,
      activeSubscription?.largeProjectQuota,
      activeSubscription?.smallProjectQuota,
      projectType,
    ]
  );

  const handleUpload = () => {
    if (shouldDisplayQuotaErrorMessage) {
      confirm({
        title: (
          <div className="ProjectCategory-TitleWrapper">
            <div>Confirm</div>
            <Tooltip
              placement="topLeft"
              title={
                <section>
                  <div>Extra fees:</div>
                  <div>Small: ${activeSubscription?.smallOverage || OVERAGE.small} / project</div>
                  <div>Large: ${activeSubscription?.largeOverage || OVERAGE.large} / project</div>
                </section>
              }
            >
              <QuestionMarkIcon className="ProjectCategory-QuestionMarkIcon" />
            </Tooltip>
          </div>
        ),
        content: <div>{t('tooltipTexts.subscriptionDoesNotCover')}</div>,
        okText: t('buttonTexts.uploadProject'),
        okButtonProps: { style: { background: colors.mainBlue } },
        onOk: () => {
          handleUploadStart();
          return false;
        },
        ...CONFIRM_PROPS,
      });
    } else {
      handleUploadStart();
    }
  };

  const handleUploadStart = () => {
    const mode = MODES.LUDICROUS;

    dispatch(resetServiceRequest());
    if (addedFiles.length) {
      setUploadInProgress(true);
      return uppy.upload();
    }
    if (projectMedias.length && isSaveAllowed && projectType) {
      dispatch(
        userUploadedProject({
          projectId,
          projectType,
          boundaries,
          mode,
        })
      );
    }
  };

  const handleCancelUpload = () => {
    if (uploadInProgress) {
      uppy.cancelAll();
      setUploadInProgress(false);
      resetBoundariesData();
    } else {
      uppy.cancelAll();
      setIsImageUploadFailed(false);
    }
    setMetadata([]);
    if (isCheckedAll) {
      toggleIsCheckedAll();
    }
    setCheckedThumbnails([]);
    dispatch(
      projectMediasRequested({
        projectId: projectId,
        mediaCategoryName: category,
      })
    );
  };

  const automatedDesignServicesProduct = useMemo(() => {
    if (activeSubscription) {
      if (activeSubscription.automatedTrueUp) {
        return t('DesignServiceSpecifications.trueUp');
      } else if (
        activeSubscription.automatedWireframeLargeOnsite ||
        activeSubscription.automatedWireframeSmallOnsite
      ) {
        return t('DesignServiceSpecifications.wireframe');
      }
    }
  }, [activeSubscription, t]);

  const handleRetry = useCallback(() => {
    // For whatever reason we don't show the progress bar except for drone image uploads
    if (isDroneImages) {
      setUploadInProgress(true);
    }
    setIsImageUploadFailed(false);
    retryFailedUploads();
  }, [isDroneImages, retryFailedUploads]);

  const resetBoundariesData = () => {
    setBoundaries(null);
    setFeatures(null);
    setDroneImagesMapViewport(MAP_DEFAULTS);
    setProjectType(null);
    setAreaInSquareFeet(0);
    setAreaInSquareMeter(0);
  };

  const handleSingleDownloadClick = () => {
    const checked = projectMedias.find((media) => media.id === checkedThumbnails[0]);
    if (checked?.imgUrl) {
      openNotification({
        type: 'success',
        title: 'Success!',
        text: `You have successfully downloaded ${checked.originalFilenameWithoutExtension}${checked.originalFileExtension}`,
      });
    } else {
      openErrorNotification();
    }
  };

  return (
    <>
      <Prompt
        when={!!addedFiles.length}
        message={(location) => {
          // usePreviewSelect will modify query strings
          // only trigger prompt if changing url
          if (location.pathname.includes('albums/Drone-Images')) {
            return true;
          }
          return t('alertMessages.unsavedChanges');
        }}
      />
      {uploadInProgress && !isImageUploadFailed && (
        <UploadOverlay
          handleCancel={() =>
            handleOpenCancelConfirm(uploadInProgress, handleCancelUpload, projectMedias.length)
          }
          uploadProgressMap={uploadProgressMap}
        />
      )}
      <div className="ProjectCategory">
        <div className="ProjectCategory-Wrapper">
          {isAdminRoute && (
            <div className="ProjectInfo-SubmittedFor">
              {project?.submittedFor &&
                `Submitted for: ${project.submittedFor.firstName} ${project.submittedFor.lastName} at ${project.submittedFor.company.name}`}
            </div>
          )}
          <ToggleLegacyViewButton toLegacy={false} />
          <h2 className="ProjectDesigns-ProjectName">{project?.name}</h2>
          <div className="ProjectCategory-Category-Wrapper">
            {!isDroneImagesDraft && (
              <div className="ProjectCategory-Category">
                <CategoryIcon className="ProjectCategory-Category-Icon" />
                <span className="ProjectCategory-Category-Name">{category}</span>
              </div>
            )}
            <Subtitle>
              {isDroneImages && isDraftOrRemote ? t('ProjectCategories.upload') : null}
            </Subtitle>
            {shouldDisplayNoFlightButton && (
              <IconButton
                icon="notapplicable"
                label={t('buttonTexts.indicateNoFlight')}
                onClick={indicateNoFlightOnClick}
                color={colors.mainBlue}
                width={'20rem'}
              />
            )}
            {!isDroneImagesDraft &&
              (checkedThumbnails.length === 1 ? (
                <StyledDownloadLink
                  id="image-download"
                  href={projectMedias.find((media) => media.id === checkedThumbnails[0])?.imgUrl}
                  download
                  rel="noreferrer"
                  className="ProjectCategory-DownloadButton"
                  onClick={handleSingleDownloadClick}
                >
                  <DownloadButtonWrapper>
                    <DownloadCloud />
                    <p>{t('buttonTexts.downloadSelected')}</p>
                  </DownloadButtonWrapper>
                </StyledDownloadLink>
              ) : (
                <ZipButton
                  files={
                    checkedThumbnails.length > 0
                      ? projectMedias.filter((media) => checkedThumbnails.includes(media.id))
                      : projectMedias
                  }
                  folderName={`${project?.name} ${category}`}
                  buttonText={
                    checkedThumbnails.length > 0
                      ? t('buttonTexts.downloadSelected')
                      : t('buttonTexts.download')
                  }
                />
              ))}
          </div>
          {automatedDesignServicesProduct ? (
            <AutomatedServiceNotificationBox product={automatedDesignServicesProduct} t={t} />
          ) : null}
          {uploadingAllowed &&
            (hasUploadPermissions ? (
              <DragDrop
                uppy={uppy}
                locale={{
                  strings: {
                    // Text to show on the droppable area.
                    // `%{browse}` is replaced with a link that opens the system file selection dialog.
                    dropHereOr: 'Drop files here or %{browse}',
                    // Used as the label for the link that opens the system file selection dialog.
                    browse: 'browse',
                  },
                }}
              />
            ) : (
              <div className={cn({ 'uppy-DragDrop--Disabled': !hasUploadPermissions })}>
                {t('tooltipTexts.uploadPermissionsDenied')}
              </div>
            ))}
          {isImageUploadFailed && (
            <RetryAlert
              error={errorMessage}
              addedFileCount={addedFiles.length}
              recoveredFileCount={projectMedias.length}
              handleCancelUpload={handleCancelUpload}
              handleRetry={handleRetry}
              isSaveAllowed={isSaveAllowed}
              uploadInProgress={uploadInProgress}
            />
          )}
          {isDroneImages && !isDraftOrRemote && !isProjectRequestedLoading ? (
            <DroneDataScore
              droneData={project?.droneData}
              projectType={project?.type}
              projectId={project?.id}
              completedDate={project?.completedDate}
              droneDataScoreAvailabilityForPricingTier={droneDataScoreAvailabilityForPricingTier}
            />
          ) : null}
          {/* @ts-ignore thinks spin shouldn't have children*/}
          <Spin
            tip={
              (!isDroneImages || isDraftOrRemote) && !isProjectMediasLoading
                ? 'Parsing location data...'
                : ''
            }
            spinning={isProjectMediasLoading && !!filesToRender.length}
            size="default"
          >
            {!!(isDroneImagesDraft && (addedFiles.length || projectMedias.length)) && (
              <ProjectTypeFilter
                handleChange={(event) => handleProjectTypeChange(event)}
                projectType={boundaries && projectType}
                areaInSquareFeet={areaInSquareFeet}
                areaInSquareMeter={areaInSquareMeter}
              />
            )}
            <div
              className={cn('ProjectCategory-Actions-Wrapper', {
                'ProjectCategory-Actions-Wrapper--Hidden': !filesToRender.length,
              })}
            >
              <div className="ProjectCategory-Actions-SelectAll-Wrapper">
                <Checkbox
                  onChange={handleCheckAll}
                  checked={isCheckedAll}
                  disabled={isImageUploadFailed || (!isDroneImages && !projectMedias.length)}
                  aria-disabled={isImageUploadFailed || (!isDroneImages && !projectMedias.length)}
                />
                <span className="ProjectCategory-Actions-SelectAll-Title">Select All</span>
                <span className="ProjectCategory-Actions-SelectAll-Subtitle">
                  • {checkedThumbnails.length} / {filesToRender.length}
                </span>
              </div>
              <div className="ProjectCategory-Actions-Buttons">
                <ViewSwitchToggle
                  isListView={selectedAlbumView === 'list'}
                  setIsListView={setIsListView}
                />
                {(!isDroneImages || isDraftOrRemote) && (
                  <Button
                    disabled={!checkedThumbnails.length || isImageUploadFailed}
                    aria-disabled={!checkedThumbnails.length || isImageUploadFailed}
                    onClick={() =>
                      handleConfirmMediaDeletion(handleDeleteThumbnails, checkedThumbnails.length)
                    }
                    className="Button--Red ProjectCategory-Button--Square"
                    aria-label={`remove selected ${pluralize(checkedThumbnails, 'upload')}`}
                  >
                    <TrashIcon />
                  </Button>
                )}
              </div>
            </div>
            <ThumbnailList
              imagePreviews={imagePreviews}
              selectedThumbnail={popup}
              thumbnails={filesToRender}
              isDraft={isDraftOrRemote}
              isDroneImages={isDroneImages}
              isListView={selectedAlbumView === 'list'}
              handleSelectImage={handleSelectImage}
              handleCheck={handleCheck}
              handleRenderMap={handleRenderMap}
              checkedThumbnails={checkedThumbnails}
              selectedImage={selectedImage}
              droneDataScoreAccess={droneDataScoreAvailabilityForPricingTier}
              isDroneDataAvailable={Boolean(Object.keys(project?.droneData ?? {}).length)}
            />
            <div className="ProjectCategory-Buttons">
              <div className="ProjectCategory-Buttons-Wrapper">
                {
                  isDraftOrRemote || !isDroneImages ? <GoBackButton /> : <div /> //temporarily rendering a div to not mess up our flex justification
                }
                {projectType && (
                  <NotificationBox
                    isError={shouldDisplayQuotaErrorMessage}
                    selectedType={projectType}
                    smallOverage={activeSubscription?.smallOverage || OVERAGE.small}
                    largeOverage={activeSubscription?.largeOverage || OVERAGE.large}
                  />
                )}
                {isDroneImagesDraft ? (
                  <Tooltip title={!isSaveAllowed && errorMessage}>
                    <Button
                      onClick={handleUpload}
                      disabled={!isSaveAllowed || isImageUploadFailed}
                      aria-disabled={!isSaveAllowed || isImageUploadFailed}
                      className="Button--Blue"
                    >
                      Upload Project
                    </Button>
                  </Tooltip>
                ) : (
                  <Button
                    onClick={!isDraftOrRemote && notesAccess ? handleNext : handleExit}
                    className="Button--White"
                  >
                    {!isDraftOrRemote && notesAccess
                      ? t('buttonTexts.next')
                      : t('buttonTexts.saveAndExit')}
                  </Button>
                )}
                <DistantPhotoReview
                  imagePreviews={imagePreviews}
                  onRemoved={handleRemoveDistantPhotos}
                  projectMedias={[...addedFiles, ...projectMedias] as UppyFile<FileMetaData>[]}
                />
              </div>
            </div>
          </Spin>
        </div>
        <div className="ProjectCategory-Element">
          <ProjectCategoryMap
            imagePreviews={imagePreviews}
            metadata={metadata}
            pin={project?.geolocation}
            selectedImagePreview={selectedImage}
            popup={popup}
            selectedMarkers={checkedThumbnails}
            setPopup={setPopup}
            disableBoundingBox={!isDroneImagesDraft}
            setSelectedImagePreview={setSelectedImage}
            boundaries={isDroneImages && filesToRender.length ? boundaries : null}
            droneImagesMapViewport={droneImagesMapViewport}
            features={features}
            setFeatures={setFeatures}
            updateOrCreateBoundaries={isDroneImagesDraft ? updateOrCreateBoundaries : null}
            projectType={isDroneImages ? projectType || project?.type || defaultProjectType : null}
            allMedia={filesToRender}
          />
        </div>
      </div>
    </>
  );
}

function ProjectCategoryWithMediaCarouselProvider(props: ProjectCategoryProps) {
  return (
    <MediaCarouselProvider>
      <ProjectCategory {...props} />
    </MediaCarouselProvider>
  );
}

export default ProjectCategoryWithMediaCarouselProvider;
