import { Thumbnail as StandardThumbnail } from '@cpm/scanifly-shared-components';
import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { MediaItem, SurveyMedia } from '@cpm/scanifly-shared-data';
import { projectCategoryAlbumPreviewerRoute } from 'helpers/constants/routes';
import { KebabMenu } from '../KebabMenu/KebabMenu';
import { KebabMenuContent } from '../KebabMenuContent/KebabMenuContent';

export const Thumbnail = ({
  image,
  selectedImages,
  handleClick,
  setOpenMiniMenuId,
  openMiniMenuId,
  toggleDeleteModal,
  handleDownload,
}: {
  image: SurveyMedia | MediaItem;
  selectedImages: Record<string, SurveyMedia | MediaItem>;
  handleClick: (
    image: SurveyMedia | MediaItem,
    e?: React.MouseEvent<HTMLElement, MouseEvent>
  ) => void;
  setOpenMiniMenuId: React.Dispatch<React.SetStateAction<string | null>>;
  openMiniMenuId: string | null;
  toggleDeleteModal: () => void;
  handleDownload?: () => void;
}) => {
  const isActive = Boolean(selectedImages[image.id]);

  const handleKebabMenuClick = useCallback(
    (e?: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (!isActive) {
        handleClick(image, e);
      }
    },
    [handleClick, image, isActive]
  );
  const history = useHistory();
  const params = useParams<{ projectId: string; categoryName: string }>();

  const onDoubleClick = () => {
    const query = new URLSearchParams({
      preview: image.id,
      legacy: '0',
    });
    history.push(
      `${projectCategoryAlbumPreviewerRoute(params.projectId, params.categoryName)}?${query.toString()}`
    );
  };

  return (
    <StandardThumbnail
      kebabMenu={
        <KebabMenu
          content={
            <KebabMenuContent
              isActive={isActive}
              selectedImages={selectedImages}
              image={image}
              handleDownload={handleDownload}
              toggleDeleteModal={toggleDeleteModal}
              setOpenMiniMenuId={setOpenMiniMenuId}
            />
          }
          isActive={isActive}
          setOpenMiniMenuId={setOpenMiniMenuId}
          openMiniMenuId={openMiniMenuId}
          onClick={handleKebabMenuClick}
          imageId={image.id}
        />
      }
      isActive={isActive}
      isAlbumView={true}
      image={image}
      handleClick={(image, e) => handleClick(image as MediaItem, e)}
      handleDoubleClick={onDoubleClick}
      withTooltip={true}
    />
  );
};
